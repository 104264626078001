import {Tr} from "@symfonia-ksef/locales/keys";
import {useIntl} from "react-intl";

export const SupplierTypeRenderer = ({supplierType}: {supplierType: number}) => {
    const intl = useIntl();
    const mapSupplierType = () => {
        switch (supplierType) {
            case 1:
                return intl.formatMessage({id: Tr.ContractorTypeSwitchesCostLabel});
            case 2:
                return intl.formatMessage({id: Tr.ContractorTypeSwitchesTradingLabel});
            case 3:
                return `${intl.formatMessage({id: Tr.ContractorTypeSwitchesCostLabel})}, ${intl.formatMessage({id: Tr.ContractorTypeSwitchesTradingLabel})}`;
            default:
                return '';
        }
    };
  
    return <div>{mapSupplierType().toLowerCase()}</div>;
};
