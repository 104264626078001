import {PageMessage} from '../../common/components/PageMessage/PageMessage';
import {FormattedMessage} from 'react-intl';
import {Tr} from '../../../locales/translationKeys';

export const AccessDenied = () => {
  return (
    <PageMessage
      title={<FormattedMessage id={Tr.accessDeniedDesktopTitle}/>}
      description={<FormattedMessage id={Tr.accessDeniedDesktopDescription }/>}
    />
  );
};
