import {FormattedMessage} from 'react-intl';
import {PageMessage} from '../../../common';
import {Tr} from '../../../../locales/translationKeys';

export const MyEnvironmentsError = () => {
  return (
    <PageMessage
      title={<FormattedMessage id={Tr.myEnvironmentsErrorTitle}/>}
      description={<FormattedMessage id={Tr.myEnvironmentsErrorDesc}/>}
    />
  );
};
