import {FC, ReactNode} from 'react';
import {Modal} from '@symfonia/brandbook';

export interface PageMessageProps {
  title: string | ReactNode;
  description: string | ReactNode;
  actions?: ReactNode;
  width?: number;
  showClose?: boolean;
}

export const PageMessage: FC<PageMessageProps> = ({title, description, actions, width, showClose = false}) => {
  return (
    <Modal 
      show={true}
      title={title}
      showCloseIcon={showClose}
      className={'max-w-[' + width + 'px]'}
    >
      {description}
      {!!actions && (
        <div className="flex justify-center mt-[24px]">
          {actions}
        </div>
      )}
    </Modal>
  );
};
