import {Tr} from '../../../../locales/translationKeys';
import {FormattedMessage} from 'react-intl';
import {PageMessage, usePageTitle} from '../../../common';

export const CompanyInactive = ({companyName}: { companyName: string }) => {
  const {setPageTitle} = usePageTitle();

  setPageTitle(companyName);

  return (
    <PageMessage
      title={<FormattedMessage id={Tr.companyInactiveTitle}/>}
      description={<FormattedMessage id={Tr.companyInactiveDesc}/>}
    />
  );
};
