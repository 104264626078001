import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {
  InvoiceBound,
  SubscriptionStateEnum,
  WebSocketNotificationFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {
  DownloadInvoicesDataModel,
  DownloadStateUserMessage,
} from '../../../../../../services/helpers/NotificationDataParsers';
import {ReactNode} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {downloadedInvoicesResultService} from '@symfonia-ksef/state/rootRepository';
import ShowPopupIcon from '../../../../../../assets/ic_show_popup.svg';
import {intl} from '../../../../../root/IntlProvider';

export const invoicesMapper = ({
                                 successItems,
                                 state, stateTime,
                                 ...rest
                               }: DownloadInvoicesModelToMap): DownloadInvoicesDataModel => {
  return ({
    ...rest,
    state: state === 0 ? DownloadStateUserMessage.Pending : DownloadStateUserMessage.Downloading,
    stateTime: stateTime ? new Date(stateTime) : undefined,
    successItems: successItems?.map?.(({invoiceBound, ...restItems}) => ({
      invoiceBound: invoiceBound === 0 ? InvoiceBound.Internal : invoiceBound ? InvoiceBound.External : undefined,
      ...restItems,
    })),
  });
};

export type DownloadInvoicesModelToMap =
  Omit<DownloadInvoicesDataModel, 'successItems' | 'state'>
  & {
  state?: number,
  stateTime?: string,
  successItems?: {
    kSeFNumber?: string,
    kSeFDate: string,
    invoiceBound?: number
  }[]
}

export class GetInvoicesEventConverter extends KSeFEventsConverter<WebsocketNotificationType.DownloadInvoices | WebsocketNotificationType.AutoFetchingInvoices, DownloadInvoicesModelToMap> {
  protected isAutoFetching!: boolean;

  constructor(type: WebsocketNotificationType.DownloadInvoices | WebsocketNotificationType.AutoFetchingInvoices, event: WebSocketNotificationFragment) {
    super(type, event);
    this.isAutoFetching = type === WebsocketNotificationType.AutoFetchingInvoices;
  }

  public get notification(): ReactNode {
    const isCancelled = this.event.State === SubscriptionStateEnum.Cancelled;
    const manualCancellation = isCancelled ? intl.formatMessage({id: Tr.getInvoicesFromKSeFCancelled}) : '';
    const {hasError, data} = this.model;
    if (hasError) {
      return this.createNotification({
        id: this.isAutoFetching ? Tr.ksefAutoFetchingInvoicesSubError : Tr.getInvoicesFromKSeFError,
        values: {manualCancellation},
      });
    }
    if (data?.imported) {
      const messageId = this.isAutoFetching ? Tr.ksefAutoFetchingInvoicesSubSuccess : Tr.getInvoicesFromKSeFSuccess;
      const values = {count: data.imported, manualCancellation};
      return this.createNotification({id: messageId, values});
    }
    return this.createNotification({
      id: isCancelled ? Tr.getInvoicesFromKSeFNoData : Tr.getInvoicesFromKSeFUpToDate,
      values: {manualCancellation},
    });
  }

  public get actionElement(): { icon: string; tooltipText: Tr } | undefined {
    const data = this.parser.parse();
    if (data?.errorItems?.length) {
      return {
        icon: ShowPopupIcon,
        tooltipText: Tr.showMessage,
      };
    }
    return undefined;
  }

  public get model(): { data: DownloadInvoicesDataModel | null, hasError: boolean } {
    const data = this.parser.parse();
    const hasError = !!(this.event.ErrorType || (!!data?.errorItems?.length && !data?.imported));
    return {data, hasError};
  }

  public async action(): Promise<void> {
    const data = this.parser.parse({reparse: true});
    downloadedInvoicesResultService.setDownloadType(this.event.Type);
    if (data) {
      downloadedInvoicesResultService.setArchivedResult(data, this.event.NotificationId).setResultIsAvailable(true);
    }
    downloadedInvoicesResultService.handleMissedInvoices() && downloadedInvoicesResultService.setModalIsActive(true);
  }

  protected override mapper(model: DownloadInvoicesModelToMap): DownloadInvoicesDataModel {
    return invoicesMapper(model);
  }
}
