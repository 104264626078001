import {KSeFStatus, WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {ReactNode} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {SendInvoice, SendInvoicesDataModel} from '../../../../../../services/helpers/NotificationDataParsers';

export type SendInvoicesModelToMap =
  Omit<SendInvoicesDataModel, 'invoices'>
  & { invoices: Array<Omit<SendInvoice, 'kSeFStatus'> & { kSeFStatus: number }> }

type SendingTypes = WebsocketNotificationType.SendingInvoices | WebsocketNotificationType.AutoSendingInvoices

type TranslationsIds = { many: { success: Tr, error: Tr }, one: { success: Tr, error: Tr } }

export class SendingInvoicesEventConverter extends KSeFEventsConverter<SendingTypes, SendInvoicesModelToMap> {
  private readonly isAutoSending!: boolean;
  private readonly translationIds: { sending: TranslationsIds, autoSending: TranslationsIds } = {
    sending: {
      many: {
        error: Tr.sendManyToKSeFError,
        success: Tr.sendManyToKSeFSuccess,
      },
      one: {
        error: Tr.sendToKSeFError,
        success: Tr.sendToKSeFSuccess,
      },
    },
    autoSending: {
      one: {
        error: Tr.autoSendToKSeFError,
        success: Tr.autoSendToKSeFSuccess,
      },
      many: {
        error: Tr.autoSendToKSeFError,
        success: Tr.autoSendToKSeFSuccess,
      },
    },
  };

  constructor(type: SendingTypes, event: WebSocketNotificationFragment) {
    super(type, event);
    this.isAutoSending = type === WebsocketNotificationType.AutoSendingInvoices;
  }

  public get notification(): ReactNode {
    const count = this.model.data?.invoices?.length ?? 0;
    const values = {count};
    const someIsApproved = !!this.model.data?.invoices?.some?.(({kSeFStatus}) => kSeFStatus === KSeFStatus.Approved);
    if (this.model.hasError && someIsApproved) {
      return this.createNotification({id: Tr.sendToKsefSendingError, values});
    }
    if (this.model.hasError && count > 1) {
      return this.createNotification({
        id: this.isAutoSending ? this.translationIds.autoSending.many.error : this.translationIds.sending.many.error,
        values,
      });
    }
    if (this.model.hasError) {
      return this.createNotification({
        id: this.isAutoSending ? this.translationIds.autoSending.one.error : this.translationIds.sending.one.error,
        values,
      });
    }
    if (count > 1) {
      return this.createNotification({
        id: this.isAutoSending ? this.translationIds.autoSending.many.success : this.translationIds.sending.many.success,
        values,
      });
    }
    return this.createNotification({
      id: this.isAutoSending ? this.translationIds.autoSending.one.success : this.translationIds.sending.one.success,
      values,

    });
  }

  public get actionElement(): undefined {
    return;
  }

  public get model(): { data: SendInvoicesDataModel | null, hasError: boolean } {
    const data = this.parser.parse();
    const count = data?.invoices?.length ?? 0;
    const everyIsApproved = data?.invoices?.every?.(({kSeFStatus}) => kSeFStatus === KSeFStatus.Approved);
    const hasError = !!this.event.ErrorType || !count || !everyIsApproved;
    return {data, hasError};
  }

  public action(): void {
    return;
  }

  protected mapper({invoices, ...rest}: SendInvoicesModelToMap): SendInvoicesDataModel | null {
    return {
      ...rest, invoices: invoices.map(invoice => ({
          ...invoice,
          kSeFStatus: KSeFEventsConverter.getMappedKSeFStatus(invoice.kSeFStatus),
        }),
      ),
    };
  }
}
