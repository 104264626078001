import {Action} from '../../../../common/types/MenuActions';
import * as React from 'react';
import {useMemo} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {SelectionI} from '../../../../root/services/TableServices/ExtendedTableService';
import {ContractorsRow} from './ContractorsTableConfig';
import {ContractorsFiltersState} from './ContractorsFiltersState';
import {ContractorSwitchTypeModalProps} from './ContractorsSwitchTypeModal';

export type ContractorsGroupActionsParams = {
  selectionState: SelectionI<ContractorsRow>;
  setSwitchTypeModal: (modalProps: ContractorSwitchTypeModalProps) => void,
  filters: ContractorsFiltersState
}

export function useContractorsGroupActions(params: ContractorsGroupActionsParams): Action<ContractorsRow>[] {
  const intl = useIntl();

  return useMemo<Action<ContractorsRow>[]>(() => {
		return [
			{
				'test-id': "changeContractorSupplierTypeMultiple",
				key: Tr.switchContractorType,
				label: intl.formatMessage({id: Tr.switchContractorType}),
				onClick: () => {
					params.setSwitchTypeModal({
						modalIsActive: true,
						contractorNips: params.selectionState.selected.map(el => el.Identifier!),
					});
				},
			},
		];
		}, []);

}