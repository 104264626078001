import {
    ContractorFiltersFieldsInput,
  } from '@symfonia-ksef/graphql';
import {FilterScopeType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ContractorsFiltersKeys, ContractorsFiltersState} from './ContractorsFiltersState';
  
  export class ContractorsFiltersUtils {
    public static readonly translationsMap: Record<ContractorsFiltersKeys, Tr> = {
      Numbers: Tr.InvoiceNumber,
    };
  
    public static buildFiltersForQuery(state: ContractorsFiltersState): ContractorFiltersFieldsInput {
      const current = Number(state.parent.scope.variant);
      const isSupplierTypeSpecified = typeof current === 'number';
      const filters: ContractorFiltersFieldsInput = {
        SupplierType: isSupplierTypeSpecified ? current : undefined,
      };
  
      for (const [key, filter] of Object.entries(state.getActiveFiltersForQuery)) {
        if (key in filters) {
          continue;
        }
        const filterKey = key as keyof ContractorFiltersFieldsInput;
        type Filter = typeof filters[typeof filterKey]
        switch (filter.type) {
          case FilterScopeType.String:
          case FilterScopeType.Number:
          case FilterScopeType.Boolean:
          case FilterScopeType.Enum:
          case FilterScopeType.UUIDs: {
            (filters[filterKey] as Filter) = filter.values.filter(Boolean);
            break;
          }
        }
      }
  
      return filters;
    }
  }
  