import {BaseSideMenuFilterState} from '@symfonia-ksef/state/filters/BaseSideMenuFilterState';
import {FilterScopeType, ListFilterType, TranslatedFilter} from '@symfonia-ksef/state/filters/BaseFilterState';
import {computed, makeObservable} from 'mobx';
import {Pills} from '../../../../root/components/FiltersPills';
import {ContractorFiltersFieldsInput} from '@symfonia-ksef/graphql';
import {intl} from '../../../../root/IntlProvider';
import {ContractorsService} from '../Contractors.service';
import {ContractorsFiltersUtils} from './ContractorsFiltersUtils';

export enum ContractorsFiltersKeys {
  Numbers = 'Numbers',
}

export class ContractorsFiltersState extends BaseSideMenuFilterState<ContractorsService, ContractorsFiltersKeys> {
  private static readonly defaultFilters: Readonly<ListFilterType<ContractorsFiltersKeys>> = Object.freeze([
    {
      key: ContractorsFiltersKeys.Numbers,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
  ]);

  constructor(contractorsService: ContractorsService) {
    super(contractorsService);
    makeObservable(this);
    this.defaultFilters = [...ContractorsFiltersState.defaultFilters];
    this.setDefaultValues();
  }

  @computed
  public get pills(): Pills {
    const convertedPills: Pills = {};
    for (const [filterKey, value] of this.pillsList.entries()) {
      const {values} = value as TranslatedFilter;
      convertedPills[filterKey] = {
        filterLabel: intl.formatMessage({id: ContractorsFiltersUtils.translationsMap[filterKey as ContractorsFiltersKeys]}),
        items: values.map(({value, key}) => ({
          key,
          label: value,
          onClick: () => {
            this.clearSingleFilter(filterKey, key, value);
            this.parent.repository.fetch();
          },
        })),
      };
    }
    return convertedPills;
  }

  @computed
  public get filtersForQuery(): ContractorFiltersFieldsInput {
    return ContractorsFiltersUtils.buildFiltersForQuery(this);
  }

  @computed
  public get filtersIsActive(): boolean {
    return Object.keys(this.pills).length > 0;
  }

  protected setDefaultValues() {
    let filterToCreate: ListFilterType<ContractorsFiltersKeys> = this.storage.loadFromStorage();

    if (!filterToCreate?.length) {
      filterToCreate = [...ContractorsFiltersState.defaultFilters];
    }

    filterToCreate.forEach(f => {
      if (Object.keys(ContractorsFiltersKeys).find(c => c === f.key)) {
        this.setActiveFilters(f.key, f.value);
      }
    });

    this.createPills();
  }
}
