import {BaseModule} from '../../../root/services/MobXServices/BaseModule';
import {computed, makeObservable} from 'mobx';
import {TableBuilder} from '../../../root/services/TableServices/TableBuilder';
import {ContractorsRepository, ContractorsRepositoryI} from './Contractors.repository';
import {
  ContractorsDataSourceHandler,
  contractorsInitialColumnsFactory,
  ContractorsRow,
  ContractorsTableI,
  contractorsTableKeysFactory,
} from './ContractorsTable/ContractorsTableConfig';
import {GetContractorsQuery, GetContractorsQueryVariables} from '@symfonia-ksef/graphql';
import {SearchService, SearchServiceI} from '../../../root/services/SearchService/SearchService';
import {ContextualMenuState} from '../Invoices/InvoicesTable/invoicesTableConfig';
import {State} from '../../../root/services/MobXServices/State';
import {ContractorsScope, ContractorsScopeI} from './ContractorsTable/ContractorsScope';
import {ContractorsFiltersState} from './ContractorsTable/ContractorsFiltersState';
import {PaginationMode} from '../../../root/services/TableServices/PaginationTableService';
import React from 'react';
import {ContractorAccountsProps, ReactSetStateType} from '../../hooks/useContractorsTableConfiguration';
import {ContractorModalProps} from '../../hooks/useInvoicesTableConfiguration';

export class ContractorsService extends BaseModule {
  public readonly repository!: ContractorsRepositoryI;
  public readonly areActionsDisabled: boolean | undefined;
  public readonly searchService!: SearchServiceI;
  public readonly filters!: ContractorsFiltersState;
  public readonly scope!: ContractorsScopeI;
  public readonly contextMenuState = new State<ContextualMenuState>({
    anchorEl: null,
    item: null,
    isOpen: false,
  });

  public readonly setContractorAccountsModalProps: ReactSetStateType<ContractorAccountsProps>;
  public readonly setContractorModalProps: ReactSetStateType<ContractorModalProps>;

  constructor(
    contractorAccountsModalPropsSetter: React.Dispatch<React.SetStateAction<ContractorAccountsProps | undefined>>,
    contractorModalPropsSetter: React.Dispatch<React.SetStateAction<ContractorModalProps | undefined>>
  ) {
    super();
    this.setContractorAccountsModalProps = contractorAccountsModalPropsSetter;
    this.setContractorModalProps = contractorModalPropsSetter;
    this.filters = new ContractorsFiltersState(this);
    this.repository = new ContractorsRepository(this.filters);
    this.searchService = new SearchService(this.repository, () => this.tableService.setPagination({page: 1}), true, undefined, 2);
    this.scope = new ContractorsScope(this);
    this.createTableService();

    makeObservable(this);
  }

  private _tableService!: ContractorsTableI;

  public get tableService(): ContractorsTableI {
    return this._tableService;
  }

  @computed
  public get contractors(): ContractorsRow[] {
    return this.tableService.rows;
  }

  protected _onMount(): void {
    this.scope.onMount();
    this.tableService.onMount();
    this.searchService.onMount();
    this.repository.fetch();
  }

  protected _onUnmount(): void {
    this.scope.onUnmount();
    this.tableService.onUnmount();
    this.searchService.onUnmount();
  }

  private getColumns() {
    return contractorsInitialColumnsFactory({
      setContractorAccountsModalProps: this.setContractorAccountsModalProps,
      setContractorModalProps: this.setContractorModalProps,
    });
  }

  private createTableService(): void {
    this._tableService = TableBuilder.create<ContractorsRow, 'GetContractors', GetContractorsQuery, GetContractorsQueryVariables>()
      .connectKeyFactory(contractorsTableKeysFactory)
      .connectColumns(this.getColumns())
      .connectSortBy({persistKey: 'contractorsSortBy'})
      .connectContext(this.contextMenuState)
      .connectDataSource(new ContractorsDataSourceHandler(this.repository))
      .connectPagination({
        lifeCycle: {mode: PaginationMode.serverSide},
        persistKey: 'contractorsPagination',
      })
      .connectSelection()
      .buildExtended();
  }
}
