import {FormattedMessage} from 'react-intl';
import {PageMessage, usePageTitle} from '../../../common';
import {Tr} from '../../../../locales/translationKeys';

export const CompanyPending = ({companyName}: { companyName: string }) => {
  const {setPageTitle} = usePageTitle();

  setPageTitle(companyName);

  return (
    <PageMessage
      title={<FormattedMessage id={Tr.companyPendingTitle}/>}
      description={<FormattedMessage id={Tr.companyPendingeDesc} values={{br: <br/>}}/>}
    />
  );
};
