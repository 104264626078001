import {DropdownListOption} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../../../root/IntlProvider';
import {action, computed, makeObservable, observable} from 'mobx';
import {BaseModule, BaseModuleI} from '../../../../root/services/MobXServices/BaseModule';
import {BasePersistService, BasePersistServiceI} from '../../../../root/services/PersistServices/BasePersistService';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {ContractorsRepositoryI} from '../Contractors.repository';
import {ContractorsService} from '../Contractors.service';

export enum ContractorsFilterType {
    SupplierTypeCost = '1',
    SupplierTypeTrading = '2',
    SupplierTypeCostAndTrading = '3',
    NoType = '0',
    All = 'All'
  }

export interface ContractorsScopeI extends BaseModuleI {
  readonly repository: ContractorsRepositoryI;

  get supplierTypeVariants(): DropdownListOption[];

  get currentVariant(): [string];

  get variant(): ContractorsFilterType;

  setVariant(variant: string): void;
}

export class ContractorsScope extends BaseModule implements ContractorsScopeI {

  private storage!: BasePersistServiceI<ContractorsFilterType>;

  constructor(private readonly contractorsService: ContractorsService) {
    super();

    this.storage = new BasePersistService<ContractorsFilterType>('supplierType', earchiveState.envObserver)
      .subscribe(variant => this.setVariant(variant, false), {load: true});

    makeObservable(this);

    this.reactionsManager.add(() => this._currentVariant, current => {
      this.contractorsService.tableService.setPagination({page: 1});
      this.contractorsService.repository.fetch();
    });
  }

  public get repository(): ContractorsRepositoryI {
    return this.contractorsService.repository;
  }

  @observable
  private _currentVariant: ContractorsFilterType = ContractorsFilterType.All;

  @computed
  public get currentVariant(): [string] {
    return [this._currentVariant];
  }

  @computed
  public get variant(): ContractorsFilterType {
    return this._currentVariant;
  }

  @computed
  public get supplierTypeVariants(): DropdownListOption[] {
    const {All, NoType, SupplierTypeCost, SupplierTypeTrading, SupplierTypeCostAndTrading} = ContractorsFilterType;
    const {
      ContractorTypeEmptyLabel,
      ContractorTypeSwitchesTradingLabel,
      ContractorTypeSwitchesCostLabel,
      ContractorTypeSwitchesAllLabel,
      ContractorTypeSwitchesCostAndTradingLabel
    } = Tr;
    const options: { value: ContractorsFilterType, label: Tr }[] = 
    [{
      label: ContractorTypeSwitchesCostLabel,
      value: SupplierTypeCost,
    }, {
      label: ContractorTypeSwitchesTradingLabel,
      value: SupplierTypeTrading,
    },
    {
      label: ContractorTypeSwitchesCostAndTradingLabel,
      value: SupplierTypeCostAndTrading,
    },
    {
      label: ContractorTypeEmptyLabel,
      value: NoType,
    }
  ];
    options.push({value: All, label: ContractorTypeSwitchesAllLabel});
    return options.map(({value, label}) => ({value, label: intl.formatMessage({id: label})}));
  }

  @action.bound
  public setVariant(variant: string, persistEnabled: boolean = true): void {
    if (this.supplierTypeVariants.some(({value}) => value === variant)) {
      this._currentVariant = variant as ContractorsFilterType;
      persistEnabled && this.storage.save(this._currentVariant);
    }
  }

  protected _onMount(): void {
    this.storage.onMount();
  }

  protected _onUnmount(): void {
    this.storage.onUnmount();
  }
}
