import {FC, useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {EnvironmentType} from '@symfonia-ksef/graphql';
import {EarchiveModuleInitialization} from './EarchiveModuleInitialization';
import {AccountPage, AuthenticationPage, PermissionsPage, TenantPage, UserPage} from './pages/Account';
import {DocumentsPage} from './pages/Documents/DocumentsPage';
import {PurchasePage} from './pages/Documents/Purchase/PurchasePage';
import {SalesPage} from './pages/Documents/Sales/SalesPage';
import {NotificationsCompanyPage} from './pages/Account/Notifications/NotificationsCompanyPage';
import {SavedOrFirstCompanyIdGuard} from './components/SavedOrFirstCompanyIdGuard';
import {LoginHub} from '../root/pages/LoginHub';
import {isDesktop} from '../common/helpers/checkCurrentPlatform';
import {DesktopRedirect} from './components/DesktopRedirect';
import Posting from '../auto-posting/Posting';
import {PostingConfigurationInitialization} from './pages/Account/Posting/PostingConfigurationInitialization';
import {isFeatureEnabled} from '../common/helpers/featureSwitch';
import {FeatureSwitchTypeEnum} from '../common/enums/FeatureSwitchTypeEnum';
import {WSEvents} from './pages/WSEvents/WSEvents';
import {EArchiveStateMobx} from './EArchiveStateMobx';
import {UploadXML} from './pages/UploadInvoices/UploadXML';
import {OcrPage} from './pages/Ocr/OcrPage';
import {PageNotFound} from './components/PageNotFound';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {BackgroundActionsPage} from './pages/Account/Authentication/BackgroundActionsPage';
import {ContractorsPage} from './pages/Account/Contractors/ContractorsPage';

interface EArchiveModuleProps {
  title: string;
}

export const earchiveStateMobx = new EArchiveStateMobx();

export const EArchiveModule: FC<EArchiveModuleProps> = ({title}) => {
  useEffect(() => {
    earchiveState.tenant.set({
      title,
      accountManagementEnabled: true,
      addTenantEnabled: false,
      moduleEnvironmentType: EnvironmentType.Company,
      contextSwitcherEnabled: true,
    });
  }, [title]);

  return (
    <Routes>
      <Route index element={<SavedOrFirstCompanyIdGuard><LoginHub/></SavedOrFirstCompanyIdGuard>}/>
      {isDesktop ? (
        <Route path="/:companyId" element={<DesktopRedirect/>}/>
      ) : (
      <Route path="/">
        <Route path="/hub/:tenantId" element={<SavedOrFirstCompanyIdGuard><LoginHub/></SavedOrFirstCompanyIdGuard>}/>
        <Route path="/hub/*" element={<SavedOrFirstCompanyIdGuard><LoginHub/></SavedOrFirstCompanyIdGuard>}/>
      </Route>
      )}
      <Route
        path="/:tenantId/*"
        element={
          <Routes>
            <Route index path="*" element={<PageNotFound/>}/>
            <Route index path="*" element={<PageNotFound/>}/>
            <Route
              path="company/:companyId/*"
              element={
                <EarchiveModuleInitialization earchiveStateMobx={earchiveStateMobx}>
                  <Routes>
                    <Route index element={<Navigate to="documents" replace/>}/>
                    <Route path="documents" element={<DocumentsPage state={earchiveStateMobx.documentsState}/>}>
                      <Route index element={<Navigate to="purchase" replace/>}/>
                      <Route path="purchase" element={<PurchasePage state={earchiveStateMobx.documentsState}/>}/>
                      <Route path="sales" element={<SalesPage state={earchiveStateMobx.documentsState}/>}/>
                    </Route>
                    {isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) && (
                      <Route path="autoposting/*" element={<Posting state={earchiveStateMobx.postingState!}/>}/>
                    )}
                    <Route
                      path="account"
                      element={
                        <AccountPage
                          state={earchiveStateMobx.accountState}
                          modules={earchiveStateMobx.moduleStatusStore.modules}
                       />
                      }
                    >
                      <Route index element={<Navigate to="user" replace/>}/>
                      <Route path="authentication" element={<AuthenticationPage/>}/>
                      <Route path="ksef_settings" element={<BackgroundActionsPage/>}/>
                      <Route path="tenant" element={<TenantPage/>}/>
                      <Route path="permissions" element={<PermissionsPage/>}/>
                      <Route path="contractors" element={<ContractorsPage/>}/>
                      <Route path="notifications" element={<NotificationsCompanyPage/>}/>
                      {isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) && (
                        <Route
                          path="posting/*"
                          element={
                            <PostingConfigurationInitialization
                              state={earchiveStateMobx.accountState.postingInitializationState}
                           />
                          }
                       />
                      )}
                      <Route path="user" element={<UserPage/>}/>
                    </Route>
                    <Route path="wsevents" element={<WSEvents/>}/>
                    <Route path="upload/invoices" element={<UploadXML/>}/>
                    <Route path="ocr/:scope" element={<OcrPage/>}/>
                    <Route path="ocr" element={<Navigate to="import" replace/>}/>
                    <Route path="*" element={<PageNotFound/>}/>
                  </Routes>
                </EarchiveModuleInitialization>
              }
           />
          </Routes>
        }
     />
    </Routes>
  );
};

export default EArchiveModule;
