import {usePageTitle} from '../../common/hooks/usePageTitle';
import {PageMessage} from '../../common';
import {FormattedMessage} from 'react-intl';
import {Tr} from '../../../locales/translationKeys';
import {AppRoutes} from '../../root/Routes';
import {intl} from '../../root/IntlProvider';
import {Link} from 'react-router-dom';

export const PageNotFound = () => {
  const {setPageTitle} = usePageTitle();

  setPageTitle(intl.formatMessage({id: Tr.pageNotFoundTitle}));

  return (
    <PageMessage
      width={600}
      title={<FormattedMessage id={Tr.pageNotFoundError}/>}
      description={<div className='text-center'><FormattedMessage id={Tr.pageNotFoundDescription}
                                     values={{
                                       br: <br/>,
                                       link: <Link className='text-primary-green' to={document.location.origin + AppRoutes.hub.address}>{intl.formatMessage({id: Tr.pageNotFoundRedirectButton})}</Link>,
                                      contactUs: <Link className='text-primary-green' target='_blank' to={'https://glosklienta.symfonia.pl/hc/pl/community/topics'}>{intl.formatMessage({id: Tr.writeUs})}</Link>,
                                      image: <img className='block mx-auto' src={"../../../assets/undraw_Goals_re_lu76 1.svg"} alt={""}/>
                                     }}
      /></div>}
    />
  );
};
