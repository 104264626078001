import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import {Tr} from '../../../../../locales/translationKeys';
import {uploadInvoicesService} from '../../../../../state/rootRepository';
import {MissingInvoicesModal} from './MissingInvoices';
import {useIntl} from 'react-intl';

export const MissingUploadedInvoicesModal = observer(() => {
  const {missingInvoices, sentInvoicesCount, modalIsActive} = uploadInvoicesService;
  const intl = useIntl();
  return <MissingInvoicesModal
    title={intl.formatMessage({id:Tr.missingUploadInvoicesTitle})}
    content={(missingInvoicesCount, allInvoicesCount) => <FormattedMessage
      id={Tr.missingUploadInvoicesContent}
      values={{
        from: <strong>{missingInvoicesCount}</strong>,
        to: <strong>{allInvoicesCount}</strong>,
      }}/>}
    missingInvoices={missingInvoices}
    requestedInvoicesCount={sentInvoicesCount}
    showButtonLabel={intl.formatMessage({id:Tr.showMissingUploadInvoicesButtonLabel})}
    tableTitle={intl.formatMessage({id:Tr.showMissingUploadInvoices})}
    open={modalIsActive}
    onClose={() => uploadInvoicesService.setModalIsActive(false)}
  />;
});
