import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {
  UpoFileType,
  UpoMissingInvoiceReason,
  WebSocketNotificationFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {getUpoResultService} from '@symfonia-ksef/state/rootRepository';
import {Tr} from '@symfonia-ksef/locales/keys';
import DownloadIcon from '../../../../../../assets/ic_download.svg';
import {UPODataModel} from '../../../../../../services/helpers/NotificationDataParsers';
import {ReactNode} from 'react';

export type UPOModelToMap =
  Omit<UPODataModel, 'missingInvoices' | 'fileType'>
  & { fileType: number, missingInvoices?: { invoiceId: string, reason: number, invoiceNumber: string, }[] }

const upoMissingInvoiceReason: Record<UpoMissingInvoiceReason, number> = {
  [UpoMissingInvoiceReason.NotExists]: 0,
  [UpoMissingInvoiceReason.NotSent]: 1,
  [UpoMissingInvoiceReason.Processing]: 2,
  [UpoMissingInvoiceReason.UpoNotGenerated]: 3,
  [UpoMissingInvoiceReason.ValidationError]: 4,
  [UpoMissingInvoiceReason.Error]: 5,
  [UpoMissingInvoiceReason.InterruptedSending]: 6,
  [UpoMissingInvoiceReason.InterruptedPending]: 7,
  [UpoMissingInvoiceReason.Ocr]: 8,
  [UpoMissingInvoiceReason.SchemaError]: 9,
};

const upoFileType: Record<UpoFileType, number> = {
  [UpoFileType.Pdf]: 0,
  [UpoFileType.Xml]: 1,
};

const upoFileTypeMap = new Map<number, UpoFileType>(Object.entries(upoFileType).map(entry => entry.reverse() as [number, UpoFileType]));
export const upoMissingInvoicesReasonMap = new Map<number, UpoMissingInvoiceReason>(Object.entries(upoMissingInvoiceReason).map(entry => entry.reverse() as [number, UpoMissingInvoiceReason]));

export class UpoEventConverter extends KSeFEventsConverter<WebsocketNotificationType.GetUpo, UPOModelToMap> {
  constructor(event: WebSocketNotificationFragment) {
    super(WebsocketNotificationType.GetUpo, event);
  }

  public get actionElement(): { icon: string; tooltipText: Tr } | undefined {
    const data = this.parser.parse();
    if (!data?.returnedInvoices || data?.missingInvoices?.length === data?.requestedInvoices?.length) {
      return undefined;
    }
    return {
      icon: DownloadIcon,
      tooltipText: Tr.downloadUPO,
    };
  }

  public get notification(): ReactNode {
    return this.createNotification(this.model.hasError ? {id: Tr.getUpoSubError} : {id: Tr.getUpoSubSuccess});
  }

  public get model(): { data: UPODataModel | null, hasError: boolean } {
    const data = this.parser.parse();
    const hasError = !!this.event.ErrorType || (!data?.returnedInvoices || data?.missingInvoices?.length === data?.requestedInvoices?.length);
    return {hasError, data};
  }

  public async action(): Promise<void> {
    const data = this.parser.parse();
    if (data) {
      getUpoResultService.setNotification(this.event).setResult(data, {manualSettled: true}).setResultIsAvailable(true);
    }
    getUpoResultService.downloadUpoFile().hasMissed && getUpoResultService.handleMissingInvoices().setModalIsActive(true);
  }

  protected override mapper(this: UpoEventConverter, {
    missingInvoices,
    fileType,
    ...rest
  }: UPOModelToMap): UPODataModel {
    return ({
      ...rest,
      fileType: this.getMappedUpoFileType(fileType),
    });
  }

  private getMappedUpoFileType(key: number): UpoFileType {
    return upoFileTypeMap.get(key) ?? UpoFileType.Pdf;
  }
}
