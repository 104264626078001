import {observer} from 'mobx-react-lite';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {MissingInvoicesModal} from './MissingInvoices';
import {downloadedInvoicesResultService} from '../../../../../state/rootRepository';
import {useMemo} from 'react';
import {SubscriptionStateEnum, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {intl} from '../../../../root/IntlProvider';

export const KSeFMissedInvoicesModal = observer(() => {
  const count = downloadedInvoicesResultService.result?.imported;
  const autoFetch = downloadedInvoicesResultService.downloadType === WebsocketNotificationType.AutoFetchingInvoices;

  const matchedModalContentTranslationId = useMemo(() => {
    if (!count && !downloadedInvoicesResultService.missingInvoices.length) {
      return Tr.getInvoicesFromKSeFUpToDate;
    }
    if (!downloadedInvoicesResultService.missingInvoices.length) {
      return Tr.downloadInvoicesSubSuccess;
    }
    return Tr.missingKsefInvoicesContentWithCount;
  }, [count, downloadedInvoicesResultService.missingInvoices.length]);

  const intl = useIntl();

  return <MissingInvoicesModal
    title={intl.formatMessage({id: autoFetch ? Tr.missingKsefAutoFetchInvoicesTitle : Tr.missingKsefInvoicesTitle})}
    content={<FormattedMessage
      id={matchedModalContentTranslationId} values={{
      count,
      manualCancellation: downloadedInvoicesResultService.notification?.State === SubscriptionStateEnum.Cancelled ? intl.formatMessage({id: Tr.getInvoicesFromKSeFCancelled}) : '',
      br: <br/>,
      range: <span
        className="font-bold">{downloadedInvoicesResultService.missingInvoices.length} z {downloadedInvoicesResultService.totalInvoicesCount}</span>,
    }}/>}
    open={downloadedInvoicesResultService.modalIsActive}
    missingInvoices={downloadedInvoicesResultService.missingInvoices}
    onClose={() => {
      downloadedInvoicesResultService.setModalIsActive(false);
    }}
    resolveButtonLabel={intl.formatMessage({id: Tr.acknowledge})}
  />;
});

