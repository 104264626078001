import {BaseModule} from '../../../root/services/MobXServices/BaseModule';
import {action, computed, makeObservable} from 'mobx';
import {InvoiceItemType, InvoicesRepository, InvoicesRepositoryI} from './Invoices.repository';
import {TableBuilder} from '../../../root/services/TableServices/TableBuilder';
import {
  GetInvoicesQuery,
  GetInvoicesQueryVariables,
  InvoiceBound,
  InvoiceDto,
  SubscriptionStateEnum,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {
  ContextualMenuState,
  InvoicesDataSourceHandler,
  invoicesInitialColumnsFactory,
  InvoicesRow,
  InvoicesTableI,
  invoicesTableKeysFactory,
} from './InvoicesTable/invoicesTableConfig';
import {earchiveStateMobx} from '../../EArchiveModule';
import {
  InvoicePreviewInterface,
  InvoicePreviewRepository,
} from '@symfonia-ksef/state/InvoicePreviewState/InvoicePreviewRepository';
import {SearchService, SearchServiceI} from '../../../root/services/SearchService/SearchService';
import {InvoicesScope, InvoicesScopeI} from './InvoicesFilters/InvoicesScope';
import {DelayType, subscribeKSeFEvents, TriggerConfig} from '../../hooks/useSubscription';
import {PaginationMode} from '../../../root/services/TableServices/PaginationTableService';
import {InvoicesFiltersState} from './InvoicesFilters/InvoicesFiltersState';
import {State, StateI} from '../../../root/services/MobXServices/State';
import {EnvSubscriber} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {envObserver, ksefEventsService} from '@symfonia-ksef/state/rootRepository';
import {InvoicesListRenderKeyI} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadInvoicesProgressTracker/DownloadInvoicesProgressTracker';

export class InvoicesService extends BaseModule {
  public readonly repository!: InvoicesRepositoryI;
  public readonly invoicePreview!: InvoicePreviewInterface<InvoiceItemType>;
  public readonly searchService!: SearchServiceI;
  public readonly scope!: InvoicesScopeI;
  public readonly filters!: InvoicesFiltersState;
  public readonly contextMenuState = new State<ContextualMenuState>({
    anchorEl: null,
    item: null,
    isOpen: false,
  });

  public readonly setContractorModalProps: React.Dispatch<
    React.SetStateAction<
      | {
          modalIsActive: boolean;
          contractorNip: string;
          contractorName: string;
        }
      | undefined
    >
  >;

  private _unsubscribeKSeFEvents?: () => void;

  constructor(
    public readonly invoiceBound: InvoiceBound,
    private readonly progressTracker: InvoicesListRenderKeyI,
    public readonly constractorModalPropsSetter: React.Dispatch<
      React.SetStateAction<
        | {
            modalIsActive: boolean;
            contractorNip: string;
            contractorName: string;
          }
        | undefined
      >
    >,
  ) {
    super();
    this.setContractorModalProps = constractorModalPropsSetter;
    this.filters = new InvoicesFiltersState(this);
    this.repository = new InvoicesRepository(this.filters);
    this.scope = new InvoicesScope(this);
    this.invoicePreview = InvoicePreviewRepository.create(
      {
        sessionStorageKey: this.isInternal ? 'salesInvoicePreviewData2' : 'purchaseInvoicePreviewData2',
      },
      this.repository,
    );

    envObserver.subscribeCompanyId(this.envIdSubscriber, true);
    envObserver.subscribeTenantId(this.tenantIdSubscriber, true);
    this.createTableService();

    this.searchService = new SearchService(this.repository, () => this.tableService.setPagination({page: 1}), true);

    makeObservable(this);

    this.reactionsManager.add(
      () => this.invoicePreview.enableInvoicePreview,
      previewEnabled => {
        this.invoicePreview.setCurrentInvoiceId(this.tableService.focusedRow?.Id);
        this.tableService.setColumns(this.getColumns(previewEnabled));
      },
    );

    this.reactionsManager.add(
      () => this.tableService.focusedRow,
      focusedRow => {
        this.invoicePreview.enableInvoicePreview && this.invoicePreview.setCurrentInvoiceId(focusedRow?.Id);
      },
    );

    this.reactionsManager.add(
      () => this.progressTracker.renderKey,
      () => this.repository.fetch(),
    );
  }

  private _tableService!: InvoicesTableI;

  public get tableService(): InvoicesTableI {
    return this._tableService;
  }

  public get isInternal(): boolean {
    return this.invoiceBound === InvoiceBound.Internal;
  }

  public get isExternal(): boolean {
    return this.invoiceBound === InvoiceBound.External;
  }

  @computed
  public get invoices(): InvoiceDto[] {
    return this.tableService.rows;
  }

  @action.bound
  public handleNextFocusedRow() {
    if (this.setDefaultFocusedRow(true)) {
      return;
    }

    if (this.tableService.focusedRowIndex < this.tableService.rows.length - 1) {
      this.tableService.setFocusedRow(this.tableService.rows[this.tableService.focusedRowIndex + 1]);
    }
  }

  @action.bound
  public handlePreviousFocusedRow() {
    if (this.setDefaultFocusedRow(true)) {
      return;
    }

    if (this.tableService.focusedRowIndex > 0) {
      this.tableService.setFocusedRow(this.tableService.rows[this.tableService.focusedRowIndex - 1]);
    }
  }

  @action.bound
  public handleFocusRowChange(item: InvoiceItemType | undefined) {
    if (!item) {
      return;
    }
    const currentRow = this.tableService.rows.find(({Id}) => item.Id === Id);
    this.tableService.setFocusedRow(currentRow);
  }

  protected _onMount(): void {
    envObserver.unsubscribeCompanyId(this.envIdSubscriber);
    envObserver.unsubscribeTenantId(this.tenantIdSubscriber);
    envObserver.subscribeCompanyId(this.envIdSubscriber, true);
    envObserver.subscribeTenantId(this.tenantIdSubscriber, true);
    this.tableService.onMount();
    this.searchService.onMount();
    this.scope.onMount();
    this.subscribeKSeFEvents();
    this.invoicePreview.persist();
    this.repository.fetch();
  }

  protected _onUnmount(): void {
    envObserver.unsubscribeCompanyId(this.envIdSubscriber);
    envObserver.unsubscribeTenantId(this.tenantIdSubscriber);
    this.tableService.onUnmount();
    this.searchService.onUnmount();
    this.scope.onUnmount();
    this.unsubscribeKSeFEvents();
    this.invoicePreview.disposeReactions();
  }

  private readonly envIdSubscriber: EnvSubscriber = envId => {
    if (envId === null) {
      return;
    }
    this.invoicePreview.configure({envId: envId || ''});
    this.invoicePreview.tenantId && this.invoicePreview.persist();
  };

  private readonly tenantIdSubscriber: EnvSubscriber = tenantId => {
    if (tenantId === null) {
      return;
    }
    this.invoicePreview.configure({tenantId});
    this.invoicePreview.envId && this.invoicePreview.persist();
  };

  private setDefaultFocusedRow(onlyForEmpty: boolean): boolean {
    if (!this.tableService.focusedRow || !onlyForEmpty) {
      const [firstRow] = this.tableService.rows;
      this.tableService.setFocusedRow(firstRow);
      return true;
    }
    return false;
  }

  private getColumns(previewEnabled: boolean) {
    return invoicesInitialColumnsFactory({
      filehubState: earchiveStateMobx.filehubState,
      moduleStatusStore: earchiveStateMobx.moduleStatusStore,
      previewModeEnabled: previewEnabled,
      invoiceBound: this.invoiceBound,
      setContractorModalProps: this.setContractorModalProps,
    });
  }

  private subscribeKSeFEvents(): void {
    const {
      SendingInvoices,
      AutoSendingInvoices,
      InvoicePostingStatusChanged,
      RegistrationNumberCreated,
      UploadInvoices,
      DownloadInvoices,
      AutoFetchingInvoices,
      DeleteInvoices,
    } = WebsocketNotificationType;

    const sharedEvents: WebsocketNotificationType[] = [
      UploadInvoices,
      InvoicePostingStatusChanged,
      RegistrationNumberCreated,
      DownloadInvoices,
      AutoFetchingInvoices,
      DeleteInvoices,
    ];
    const events = this.isExternal ? sharedEvents : [...sharedEvents, SendingInvoices, AutoSendingInvoices];

    this._unsubscribeKSeFEvents = subscribeKSeFEvents(() => this.repository.fetch(), {
      subscriptionService: ksefEventsService,
      statuses: [SubscriptionStateEnum.Finished, SubscriptionStateEnum.Cancelled],
      trigger: TriggerConfig.ALL,
      delay: {type: DelayType.DEBOUNCE, time: 1000},
      types: events,
    });
  }

  private unsubscribeKSeFEvents(): void {
    this._unsubscribeKSeFEvents?.();
  }

  private createTableService(): void {
    this._tableService = TableBuilder.create<
      InvoicesRow,
      'GetInvoices',
      GetInvoicesQuery,
      GetInvoicesQueryVariables,
      StateI<ContextualMenuState>
    >()
      .connectKeyFactory(invoicesTableKeysFactory)
      .connectColumns(this.getColumns(this.invoicePreview.enableInvoicePreview))
      .connectSortBy({persistKey: this.isInternal ? 'internalInvoicesSortBy' : 'externalInvoicesSortBy'})
      .connectContext(this.contextMenuState)
      .connectDataSource(new InvoicesDataSourceHandler(this.repository))
      .connectPagination({
        lifeCycle: {mode: PaginationMode.serverSide},
        persistKey: this.isInternal ? 'internalInvoicesPagination' : 'externalInvoicesPagination',
      })
      .connectClickableRow({persistKey: this.isInternal ? 'internalFocusedInvoiceId' : 'externalFocusedInvoiceId'})
      .connectSelection()
      .buildExtended();
  }
}
