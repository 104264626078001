import {UserMenuDropdown} from '../UserMenuDropdown/UserMenuDropdown';
import {isEarchive, isLoginHub} from '../../../common/helpers/checkCurrentPath';
import {CompanyDropdownComponent} from './CompanyDropdownComponent';
import {
  Avatar,
  AvatarSize,
  ComponentWithDropdown,
  Icon,
  IconColor,
  IconSize,
  IconState,
  IconSvg,
  IconVariant,
  TabProps,
} from '@symfonia/brandbook';
import React, {FC, useEffect} from 'react';
import {useDeviceViewport, EventsBell} from '@symfonia/symfonia-ksef-components';
import {earchiveState, wsEventsRepository} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';
import {AppRoutes} from '../../../root/Routes';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {KSeFConnectionStatus} from '../KSeFConnectionStatus/KSeFConnectionStatus';
import {PackageSubscription} from '../PackageSubscription/PackageSubscription';
import {EnvironmentType, SubscriptionType} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';

interface UserMenuProps {
  tabs: ComponentWithDropdown<TabProps>[];
  isAdmin: boolean;
}

export const UserMenu: FC<UserMenuProps> = observer(({tabs, isAdmin}) => {
  const open = earchiveState.layoutState.openDrawer;
  const openNavMenu = earchiveState.layoutState.openNavMenu;
  const setOpenNavMenu = earchiveState.layoutState.setOpenNavMenu;
  const setOpen = earchiveState.layoutState.setOpenDrawer;
  const {FirstName, LastName} = earchiveState.company.currentEnvironment ?? {};
  const {isTablet} = useDeviceViewport(earchiveState.layoutState.setViewport);
  const intl = useIntl();
  const navigate = useNavigate();
  const {tenantId, companyId, currentEnvironment} = earchiveState.company;
  const isDemo = earchiveState.layoutState.isDemo;
  const {moduleEnvironmentType} = earchiveState.tenant.state;
  const {environments} = earchiveState.environments;
  const isTenant = !!environments.find(el => el.Type === EnvironmentType.Tenant && tenantId === el?.Tenant?.Id);
  const isEnterprise = earchiveState.packageStatistics.subscriptionType === SubscriptionType.Enterprise;
  const iconsAppearanceClass = ' ml-[10px] min-w-[24px]';

  const iconsAppearance = {
    [IconState.DEFAULT]: {color: IconColor.GREY_800, variant: IconVariant.CONTOUR, size: IconSize.SM},
  };

  useEffect(() => {
    setOpenNavMenu(false);
  }, [isTablet]);

  return (
    <>
      {currentEnvironment && isEarchive() && !isLoginHub() && (
        <>
          <KSeFConnectionStatus/>
          {!isEnterprise &&
            <PackageSubscription
              packageStatistics={earchiveState.packageStatistics}
              isDemo={isDemo && moduleEnvironmentType === EnvironmentType.Company}
              isAdmin={isAdmin}
              packageUsageFeedbackEnabled={!isTenant}
            />
          }
          <EventsBell
            redirect={() => navigate(`/earchive/${tenantId}/company/${companyId}/wsevents`)}
            tooltipWidth="200"
            badge={wsEventsRepository.eventsNumber}
            tooltipContent={
              wsEventsRepository.eventsShortList?.length > 0
                ? intl.formatMessage(
                    {id: Tr.unreadMessageTooltip},
                    {badgeNumber: wsEventsRepository.eventsShortList?.length},
                  )
                : intl.formatMessage({id: Tr.wsEventsGridNoUnreadData})
            }
          />
        </>
      )}

      {earchiveState.company.currentEnvironment && isEarchive() && !isLoginHub() && <CompanyDropdownComponent/>}
      <Avatar
        size={AvatarSize.MD}
        className={'ml-[20px]'}
        userName={FirstName}
        userSurname={LastName}
        onClick={() => setOpen(!open)}
      />
      {isTablet && (
        <>
          {openNavMenu ? (
            <Icon
              svg={IconSvg.CLOSE}
              appearance={iconsAppearance}
              onClick={() => setOpenNavMenu(false)}
              className={iconsAppearanceClass}
            />
          ) : (
            <Icon
              svg={IconSvg.MENU}
              appearance={iconsAppearance}
              onClick={() => setOpenNavMenu(true)}
              className={iconsAppearanceClass}
            />
          )}
        </>
      )}
      {open && (
        <div
          className={
            'fixed bg-white flex-col z-50 p-[30px] ' +
            'h-[408px] w-[461px] shadow-xl rounded-xl top-[66px] right-[50px]'
          }
        >
          <UserMenuDropdown userName={FirstName} userSurname={LastName}/>
        </div>
      )}
      {isTablet && (
        <div
          className={
            (openNavMenu ? 'flex' : 'hidden') +
            ' fixed bg-white flex-col z-50 p-[20px] pb-[20px] ' +
            'h-auto w-[390px] shadow-xl rounded-xl top-[66px] right-[20px]'
          }
        >
        {tabs.map((t, idx) => {
          return (
            <button
              className={`flex w-full mt-[20px] mb-[10px] ${t.className}`}
              onClick={() => navigate(t.href ?? AppRoutes.eArchive.address)}
              key={`${idx}-${t.text}`}
            >
              <p className={'ml-[10px] text-left'}>{t.text}</p>
            </button>
          );
        })}
      </div>)}
    </>
  );
});
