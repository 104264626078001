import {BaseModule} from '../../../root/services/MobXServices/BaseModule';
import {TableBuilder} from '../../../root/services/TableServices/TableBuilder';
import {
  WsEventsQuery,
  WsEventsRepository,
  WsEventsVariables,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsEventsRepository';
import {wsEventsRepository} from '@symfonia-ksef/state/rootRepository';
import {PaginationMode} from '../../../root/services/TableServices/PaginationTableService';
import {ExtendedTableServiceI} from '../../../root/services/TableServices/ExtendedTableService';
import {
  createClickableRowConfig,
  createColumns,
  KSeFEventsRow,
  KSeFEventsTableDataSourceComponents,
} from './services/KSeFEvents.config';

export type KSeFEventsTableI = ExtendedTableServiceI<KSeFEventsRow, 'GetWebsocketNotificationsList', WsEventsQuery, WsEventsVariables>


export class KSeFEventsService extends BaseModule {
  public readonly repository!: WsEventsRepository;

  constructor() {
    super();
    this.repository = wsEventsRepository;
    this.createTableService();
  }

  private _tableService!: KSeFEventsTableI;

  public get tableService(): KSeFEventsTableI {
    return this._tableService;
  }

  public createTableService(): void {
    this._tableService = TableBuilder.create<KSeFEventsRow, 'GetWebsocketNotificationsList', WsEventsQuery, WsEventsVariables>()
      .connectKeyFactory((row) => row.Id)
      .connectColumns(createColumns(this.repository))
      .connectFilter(row => !row.Visited, false)
      .connectClickableRow(createClickableRowConfig(this.repository))
      .connectDataSource(new KSeFEventsTableDataSourceComponents(this.repository))
      .connectPagination({lifeCycle: {mode: PaginationMode.clientSide}})
      .buildExtended();
  }

  protected _onMount(): void {
    this.tableService.onMount();
  }

  protected _onUnmount(): void {
    this.tableService.onUnmount();
  }
}

