import {ReactNode, useCallback, useState} from 'react';
import {WhiteListModelFragment} from '@symfonia-ksef/graphql';

export type ReactSetStateType<T> = React.Dispatch<React.SetStateAction<T | undefined>>;
export interface ContractorAccountsProps {
	modalIsActive: boolean;
	contractorName?: string;
	whiteListStatus?: WhiteListModelFragment;
}

export const useContractorsTableColumnsConfig = () => {
  const [contractorAccountsModalProps, setContractorAccountsModalProps] = useState<ContractorAccountsProps>();
  const closeContractorAccountsModal = useCallback(() => setContractorAccountsModalProps(current => {
    if (!current) {
      return current;
    }
    return {...current, modalIsActive: false};
  }), []);

  return {
    closeContractorAccountsModal,
    contractorAccountsModalProps,
    setContractorAccountsModalProps,
  };
};
