import {Tr} from '@symfonia-ksef/locales/keys';
import {DialogModal} from '@symfonia/symfonia-ksef-components';
import {ContractorAccountsProps} from '../../../hooks/useContractorsTableConfiguration';
import {ContractorAccountsNumbersList} from '../../../components/InvoicesTable/Components/ContractorAccountsNumbersList';
import {useIntl} from 'react-intl';

interface ContractorsAccountsModalProps {
	close: () => void;
	modalProps: ContractorAccountsProps;
}    

export const ContractorsAccountsModal = ({close, modalProps}: ContractorsAccountsModalProps) => {
const intl = useIntl();
  return (
    <DialogModal
			className={'min-w-[500px]'}
			open={modalProps.modalIsActive}
			onCancel={close}
			setMaxWidth={false}
			testId={`${ modalProps.contractorName }ContractorAccountsModal`}
      title={<div>{intl.formatMessage({id: Tr.bankAccounts})}</div>}
		>
      <p className="mb-2">{modalProps.contractorName}</p>
      <ContractorAccountsNumbersList model={modalProps.whiteListStatus}/>
    </DialogModal>
  );
};
