import {
  DeleteInvoicesMutationVariables,
  UpoMissingInvoiceReason,
  WebsocketErrorType,
  WebSocketNotificationFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {action, computed, makeObservable, observable} from 'mobx';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {NotificationDataService} from '../../services/NotificationDataService';
import {DeleteInvoicesDataModel, InvoiceNonRemovableReason} from '../../services/helpers/NotificationDataParsers';
import {AlertConfig} from '../../services/helpers/AlertService';

import {
  NotificationDataResultManager,
  NotificationDataResultManagerI,
} from '../../services/NotificationDataResultManager';
import {DeleteInvoicesJobRunner, DeleteInvoicesJobRunnerI} from '../../services/KSeFJobRunners/DeleteInvoicesJobRunner';
import {MissingInvoiceModel} from '../../modules/earchive/models';
import {intl} from '../../modules/root/IntlProvider';
import {ToastVariant} from '@symfonia/brandbook';
import {wsEventsRepository} from '@symfonia-ksef/state/rootRepository';
import {CompanyState} from '@symfonia-ksef/state/EarchiveState/CompanyState';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export const LOCAL_STORAGE_DATES_KEY = 'downloadInvoicesDateRanges';

export const getDateRangesLocalStorageKey = (companyId: string) => companyId + '.' + LOCAL_STORAGE_DATES_KEY;


export interface ExceptionDetailsListModel {
  ExceptionCode: string | undefined,
  ExceptionDescription: string | undefined,
}

export interface ErrorJSONResponseModel {
  ServiceCtx: string | undefined,
  ServiceCode: string | undefined,
  ServiceName: string | undefined,
  Timestamp: string | undefined,
  ReferenceNumber: string | undefined,
  ExceptionDetailList: ExceptionDetailsListModel[]
}


export interface IDeleteIncoviceResultService {
  open: boolean;
  resultIsAvailable: boolean;
  errorType: WebsocketErrorType | undefined;
  hasError: boolean;
  modalIsActive: boolean;
  invoicesIds: string[];

  setModalIsActive(isActive: boolean): void;

  closeModalRemoveNotification(): void;

  setResultIsAvailable(isAvailable: boolean): this;

  setErrorType(errorType: WebsocketErrorType | undefined): this;
}

export type DeleteInvoiceModelToMap =
  Omit<DeleteInvoicesDataModel, 'missingInvoices' | 'fileType'>
  & { deleted: number, errorItems?: { invoiceId: string, invoiceDate?: string, invoiceNumber: string, reason: number }[] }

export class DeleteIncoviceResultService extends NotificationDataService<WebsocketNotificationType.DeleteInvoices, DeleteInvoicesMutationVariables, DeleteInvoicesJobRunnerI, DeleteInvoicesDataModel> implements IDeleteIncoviceResultService {

  @observable
  public invoicesIds: string[] = [];

  @observable.ref
  public open = false;

  @observable
  public downloadType: WebsocketNotificationType | undefined = undefined;

  @observable
  public hasError: boolean = false;

  @observable
  public modalIsActive: boolean = false;

  @observable
  public defaultDateChanged: boolean = false;

  protected dataResultManager: NotificationDataResultManagerI<DeleteInvoicesDataModel>;

  constructor(private readonly companyState: CompanyState, earchiveState: EArchiveState) {
    super(companyState.envObserver, earchiveState, () => new DeleteInvoicesJobRunner(companyState.envObserver, earchiveState));
    makeObservable(this);
    this.dataResultManager = new NotificationDataResultManager<DeleteInvoicesDataModel>(this);
  }

  @computed.struct
  public get successNotification(): AlertConfig {

    if (!this.currentResult && !this.errorType) {
      return {id: Tr.getInvoicesFromKSeFSuccess, values: {count: '?'}};
    }

    if (!this.currentResult?.errorItems?.length && !this.currentResult?.deleted) {
      return {id: Tr.getInvoicesFromKSeFUpToDate};
    }

    if (!this.currentResult.deleted) {
      return {id: Tr.getInvoicesFromKSeFError, color: ToastVariant.ERROR};
    }

    return {id: Tr.getInvoicesFromKSeFSuccess, values: {count: this.currentResult.deleted}};
  }

  @computed
  public get currentResult(): DeleteInvoicesDataModel | null {
    return this.dataResultManager.currentResult;
  }

  @computed
  public get missingInvoices() {
    if (!this.currentResult || !this.currentResult.errorItems) {
      return [];
    }
    const {errorItems} = this.currentResult;
    return errorItems.reduce<MissingInvoiceModel[]>((items, item) => {
      if (item?.reason && item?.invoiceNumber) {
        const {reason, invoiceNumber} = item;
        items.push({
          reason: UpoMissingInvoiceReason.Error,
          invoiceNumber: invoiceNumber,
          message: this.mapInvoiceNonRemovableReason(reason),
        });
      }
      return items;
    }, []);
  }

  @action.bound
  public setDefaultDateChanged(changed: boolean): void {
    this.defaultDateChanged = changed;
  }

  @action.bound
  public setOpen(): void {
    this.open = !this.open;
  }

  @action.bound
  public setArchivedResult(result: DeleteInvoicesDataModel, NotificationId: string): this {
    this.dataResultManager.setArchivedResult(result, NotificationId);
    return this;
  }

  @action.bound
  public setDownloadType(downloadType: WebsocketNotificationType | undefined): this {
    this.downloadType = downloadType;
    return this;
  }

  public setModalIsActive(isActive: boolean): this {
    this.modalIsActive = isActive;
    return this;
  }

  public closeModalRemoveNotification() {
    this.modalIsActive = false;
    wsEventsRepository.setMessageRead(this.variables.NotificationId);
  }

  public checkIsPending(invoiceId: string | undefined): boolean {
    if (!invoiceId) {
      return false;
    }
    return this.activeEvents.some(e => e.data?.invoicesId?.includes?.(invoiceId) ?? false);
  }

  public override checkIsReady(): boolean {
    return this.envId && this.variables.NotificationId && this.resultIsAvailable;
  }

  protected async onSuccess(data: WebSocketNotificationFragment): Promise<void> {
    super.onSuccess(data);
    if (!this.result) {
      return;
    }
    this.dataResultManager.setFetchingResult({...this.result}, this.notification);
    this.handleTableUpdate();
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void {
    console.error({errors, error});
    this.setHasError(true);
  }

  protected override beforeFetch(): void | Promise<void> {
    this.setHasError(false);
  }

  private handleTableUpdate(): { updated: boolean } {
    const shouldUpdate = this.currentResult?.deleted !== undefined ? this.currentResult?.deleted >= 0 : false;
    return {updated: shouldUpdate};
  }

  @action
  private setHasError(hasError: boolean): void {
    this.hasError = hasError;
  }

  private mapInvoiceNonRemovableReason(reason: InvoiceNonRemovableReason): string {
    const {formatMessage} = intl;
    const {currentEnvironment} = this.companyState;
    const currentUser = currentEnvironment?.FirstName + ' ' + currentEnvironment?.LastName;
    switch (+reason) {
      case InvoiceNonRemovableReason.None:
        return formatMessage({id: Tr.InvoiceNonRemovableReasonNone});
      case InvoiceNonRemovableReason.NotOwner:
        return formatMessage({id: Tr.InvoiceNonRemovableReasonNotOwner}, {currentUser: currentUser});
      case InvoiceNonRemovableReason.WrongType:
        return formatMessage({id: Tr.InvoiceNonRemovableReasonWrongType});
      case InvoiceNonRemovableReason.WrongStatus:
        return formatMessage({id: Tr.InvoiceNonRemovableReasonWrongStatus});
      case InvoiceNonRemovableReason.AlreadyExported:
        return formatMessage({id: Tr.InvoiceNonRemovableReasonAlreadyExported});
      case InvoiceNonRemovableReason.ServerError:
        return formatMessage({id: Tr.InvoiceNonRemovableReasonServerError});
      case InvoiceNonRemovableReason.NotFound:
        return formatMessage({id: Tr.InvoiceNonRemovableReasonNotFound});
      default:
        return formatMessage({id: Tr.InvoiceNonRemovableReasonNone});
    }
  }
}
