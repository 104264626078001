import {GroupedActionTypeEnum} from '../models';
import {
  InvoiceDto,
  InvoicePostingStatus,
  KSeFStatus,
  UpoFileType,
  useGetInvoiceFileContentActionGroupLazyQuery,
} from '@symfonia-ksef/graphql';
import {
  downloadInvoicesZip,
  exportToSymfoniaDesktop,
} from '../components/InvoicesTable/ActionMenus/InvoicesViewServices';
import {useInvoicesTableMethods} from './useInvoicesTableMethods';
import {
  authState,
  checkWhiteListService,
  deleteInvoiceService,
  earchiveState,
  getUpoResultService,
} from '@symfonia-ksef/state/rootRepository';
import * as React from 'react';
import {useMemo} from 'react';
import {isExportToSymfoniaFKAvailable, isPostingAvailableForInvoice} from '../helpers/postingStatusHelpers';
import {FormattedMessage} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {checkIsAdmin} from '../../../helpers/permissionsHelper';
import {ToastVariant} from '@symfonia/brandbook';
import {SelectionI} from '../../root/services/TableServices/ExtendedTableService';
import {InvoicesRow} from '../modules/Invoices/InvoicesTable/invoicesTableConfig';
import {Row} from '../../root/services/TableServices/BaseTableService';

export const useInvoicesViewButtonsAndFiltersMethods = (selectionState?: SelectionI<InvoicesRow>) => {
  const selectedInvoices = selectionState?.selected ?? [];
  const environmentId = earchiveState.company.envId;
  const {company: {autoPosting}, invoicesContext} = earchiveState;
  const acceptBeforeSendToFKIsActive = !!autoPosting?.AcceptBeforeSendToFK?.IsActive;
  const addAlert = earchiveState.alertsState.addAlert;
  const {
    sendToKSeF,
    runPosting,
    removePending,
    publishInvoicesByServiceBusOrAPI,
    publishInvoices,
  } = useInvoicesTableMethods();
  const upoEnabled = useMemo<boolean>(() => selectedInvoices.some(invoice => invoice.KSeFStatus === KSeFStatus.Approved && invoice.CanDownloadUpo), [selectedInvoices]);
  const isAdmin = checkIsAdmin();
  const [GetInvoiceFileContentActionGroupQuery] = useGetInvoiceFileContentActionGroupLazyQuery({
    context: {
      envId: environmentId,
    },
  });

  const downloadInvoices = async (invoiceIds: string[]) => await downloadInvoicesZip(invoiceIds, GetInvoiceFileContentActionGroupQuery);

  const startPostingProcess = (selected: Row<InvoiceDto>[] | undefined) => {
    if (!selected) {
      return;
    }
    const unavailableInvoices: InvoiceDto[] = [];
    invoicesContext.set({selectedInvoices: selected});
    selected.forEach(invoice => {
      if (!isPostingAvailableForInvoice(invoice)) {
        unavailableInvoices.push(invoice);
      }
    });

    if (unavailableInvoices.length > 0) {
      invoicesContext.set({postingUnavailableInvoices: unavailableInvoices});
      invoicesContext.set({isPostingPartlyNotAvailableModalOpen: true});
    } else {
      runPosting(selected.map(x => x.Id));
    }
  };

  const sendToFKStatusVerification = (Invoices: InvoiceDto[]) => {
    invoicesContext.set({selectedInvoices: Invoices});
    if (Invoices.some(x => x.InvoicePostingStatus === InvoicePostingStatus.DecreeDownloaded || x.InvoicePostingStatus === InvoicePostingStatus.DecreeSent ||
      (acceptBeforeSendToFKIsActive && !x.IsDecreeAccepted))) {
      invoicesContext.set({isSentToKsefInvoicesModalOpen: true});
    } else senToFKDecreeVerification(Invoices);
  };

  const senToFKDecreeVerification = (Invoices: InvoiceDto[]) => {
    invoicesContext.set({isSentToKsefInvoicesModalOpen: false, selectedInvoices: Invoices});
    if (Invoices.map(x => x.InvoicePostingStatus).some(x =>
      x === InvoicePostingStatus.NotAllowed ||
      x === InvoicePostingStatus.Posting ||
      x === InvoicePostingStatus.PostingFailed ||
      x === InvoicePostingStatus.ReadyForPosting,
    )) {
      invoicesContext.set({isInvoicesWithoutDecreeModalOpen: true});
    } else publishInvoicesWithModal(Invoices);
  };

  const publishInvoicesWithModal = (Invoices: InvoiceDto[]) => {
    invoicesContext.set({selectedInvoices: Invoices});
    addAlert(
      <FormattedMessage id={Tr.downloadInvoiceToFKInfo}/>,
      ToastVariant.INFO,
      {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      },
    );


    if (invoicesContext.isInternal) {
      publishInvoices(Invoices.filter((invoice) =>
        ![InvoicePostingStatus.NotAllowed, InvoicePostingStatus.Posting, InvoicePostingStatus.PostingFailed, InvoicePostingStatus.ReadyForPosting].includes(invoice.InvoicePostingStatus)).map(x => x.Id));
    } else {
      if (Invoices.map(x => x.InvoicePostingStatus).some(x => x === InvoicePostingStatus.DecreeDownloaded || x === InvoicePostingStatus.DecreeSent)) {
        invoicesContext.set({isPublishInvoicesModalOpen: true});
      } else publishInvoicesByServiceBusOrAPI(Invoices);
    }
  };

  const selectedInvoicesIds = useMemo(() => selectedInvoices.map(x => x.Id + ''), [selectedInvoices]);
  const selectedInvoicesToSendIds = useMemo(() => selectedInvoices.filter(x => [KSeFStatus.Error, KSeFStatus.NotSent].includes(x.KSeFStatus)).map(x => x.Id + ''), [selectedInvoices]);
  const exportToSymfoniaFKDisabled = selectedInvoices.every(invoice => !isExportToSymfoniaFKAvailable(invoice, acceptBeforeSendToFKIsActive));

  const deleteInvoicesButtonDisabled = (!selectedInvoices.some(x => x.CreatedBy === authState.currentUserEmail) && !isAdmin) || !selectedInvoices.some(x => x.KSeFStatus === KSeFStatus.Ocr);

  const onGroupedAction = (action: GroupedActionTypeEnum) => {
    const selectedInvoicesIds = selectionState?.selected?.map?.(x => x.Id + '') ?? [];
    let returnValue;
    switch (action) {
      case GroupedActionTypeEnum.whiteListCheck:
        checkWhiteListService.jobRunner.configure({InvoiceIds: selectedInvoicesIds, Date: new Date()}).fetch();
        return;
      case GroupedActionTypeEnum.sendToKSeF:
        return sendToKSeF(selectedInvoicesToSendIds);
      case GroupedActionTypeEnum.getUpoPdf:
        return upoEnabled && environmentId && getUpoResultService.jobRunner.run({
          InvoiceIds: selectedInvoicesIds,
          UpoFileType: UpoFileType.Pdf,
        });
      case GroupedActionTypeEnum.getUpoXml:
        return upoEnabled && environmentId && getUpoResultService.jobRunner.run({
          InvoiceIds: selectedInvoicesIds,
          UpoFileType: UpoFileType.Xml,
        });
      case GroupedActionTypeEnum.exportToSymfoniaTrade:
        return exportToSymfoniaDesktop(selectedInvoices);
      case GroupedActionTypeEnum.exportToSymfoniaFinancesAndAccountancy:
        return sendToFKStatusVerification(selectedInvoices);
      case GroupedActionTypeEnum.post:
        return startPostingProcess(selectionState?.selected);
      case GroupedActionTypeEnum.downloadInvoicesZip:
        return downloadInvoices(selectedInvoicesIds);
      case GroupedActionTypeEnum.deleteInvoices:
        returnValue = deleteInvoiceService.jobRunner.run({
          InvoiceIds: selectedInvoicesIds,
        });
        return returnValue;
    }
  };
  return {
    onGroupedAction,
    upoEnabled,
    removePending,
    selectedInvoicesIds,
    exportToSymfoniaFKDisabled,
    deleteInvoicesButtonDisabled,
    publishInvoicesByServiceBusOrAPI,
    senToFKDecreeVerification,
  };
};
