import {MultiSelectModel} from '@symfonia/symfonia-ui-components';
import {ListFilterType} from '../../layout/components/Filters/FiltersState';

import dayjs from 'dayjs';
import {TreeViewDropdownModel} from '../components/TreeViewDropdown/TreeViewDropdown';
import {toDateOnly, toDateTimeOffset} from './dateParser';
import {DropdownWithLabelModel} from '../components/DropdownWithLabel/DropdownWithLabel';
import {DropdownListOption} from '@symfonia/symfonia-ksef-components';
import {FilterItem, FilterItems} from '@symfonia/symfonia-ksef-components';
import {Key} from 'react';


const isEmpty = (value: any): boolean => {
  if (isObject(value)) {
    return !Object.keys(value).length;
  }
  if (isArray(value)) {
    return !value.length;
  }
  return value === undefined || value === null;
};

export const removeEmptyValues = <T extends Partial<Record<string, any>>>(values: T): T => {
  return Object.entries(values).reduce<T>((reduced, [key, value]) => {
    if (isObject(value) && !isEmpty(value)) {
      reduced[key as keyof T] = removeEmptyValues(value);
    }
    if (isEmpty(value) || isEmpty(reduced[key as keyof T])) {
      delete reduced[key as keyof T];
    }
    return reduced;
  }, values);
};

const isObject = (value: any) => value && Object({}).toString() === Object(value).toString();
const isArray = (value: any) => Array.isArray(value);

export function removeDuplicates(arr: MultiSelectModel[]): MultiSelectModel[] {
  return arr.filter(
    (model, index, arr) =>
      !arr.find(({key, value}, arrIndex) => index !== arrIndex && (model.value === value || model.key === key)),
  );
}

export function convertToMapDropdownListOption<T = any>(
  obj: T[] | undefined,
  mapper: (el: T) => DropdownListOption<any>,
): Map<Key, DropdownListOption<any>> {
  const multipleSelectItems = new Map();
  obj?.map(mapper)?.forEach((e, idx) => {
    multipleSelectItems.set(idx, e);
  });
  return multipleSelectItems;
}

export function convertToDropdownListOption<T = any>(
  obj: T[] | undefined,
  mapper: (el: T) => DropdownListOption<any>,
): DropdownListOption[] {
  return obj?.map(mapper)
    ?.filter((a, index, self) => index === self.findIndex((item) => item.value === a.value)) ?? [];
}

export function convertToFilterItems<T = any>(
  obj: T[] | undefined,
  mapper: (el: T) => FilterItem,
): FilterItems {
  return obj?.map(mapper)
    ?.filter((a, index, self) => index === self.findIndex((item) => item.value === a.value)) ?? [];
}

export function convertToMultiSelectType<T = any>(
  obj: T[] | undefined,
  mapper: (el: T) => MultiSelectModel,
  removeDuplicatesDisabled?: boolean,
): MultiSelectModel[] {
  const multipleSelectItems = obj?.map(mapper)
    ?.filter((a, index, self) => index === self.findIndex((item) => item.key === a.key)) ?? [];
  return removeDuplicatesDisabled ? multipleSelectItems : removeDuplicates(multipleSelectItems);
}

export function convertToTreeViewDropdownModel<T = any>(
  obj: T[] | undefined,
  mapper: (el: T) => TreeViewDropdownModel,
): TreeViewDropdownModel[] {
  return obj?.map(mapper)
    ?.filter((a) => a.key !== undefined)
    ?.filter((a, index, self) => index === self.findIndex((item) => item.key === a.key)) ?? [];
}

export function convertToDropdownWithLabelModel<T = any>(
  obj: T[] | undefined,
  mapper: (el: T) => DropdownWithLabelModel,
): DropdownWithLabelModel[] {
  return obj?.map(mapper)
    ?.filter((a) => a.key !== undefined)
    ?.filter((a, index, self) => index === self.findIndex((item) => item.key === a.key)) ?? [];
}

export function areDatesEqual(dateA?: Date, dateB?: Date) {
  if (!dateA && !dateB) {
    return true;
  }
  return (
    dateA?.getFullYear() === dateB?.getFullYear() &&
    dateA?.getMonth() === dateB?.getMonth() &&
    dateA?.getDate() === dateB?.getDate()
  );
}

export function listFilterToArray(listFilter?: ListFilterType): string[] | undefined {
  return listFilter?.filter(item => item.value !== '').map(item => item.value);
}

export function isListFilterEqualToActive(filterA?: string[], filterB?: string[]): boolean {
  if ((filterA === undefined || (filterA && filterA.length === 0)) && filterB === undefined) {
    return true;
  }
  if (filterA && ((filterA.length > 0 && !filterB) || filterA.length !== (filterB && filterB.length))) {
    return false;
  }
  return !!filterB?.every(filter => filterA?.find(f => filter === f));
}

export function normalizeDate(start: boolean, date?: Date, dateTimeOffset: boolean = true) {
  if (date) {
    const newDate = start ? dayjs(date).startOf('d') : dayjs(date).endOf('d');
    return dateTimeOffset ? toDateTimeOffset(newDate.set('minutes', newDate.get('minutes')).toDate()) : toDateOnly(newDate);
  }
  return undefined;
}

export function normalizeFilterDate(date?: { DateFrom?: Date; DateTo?: Date; Specific?: Date }, config?: { toDateTimeOffset?: boolean }) {
  if (date?.Specific) {
    return {
      DateFrom: normalizeDate(true, date?.Specific, !!config?.toDateTimeOffset),
      DateTo: normalizeDate(false, date?.Specific, !!config?.toDateTimeOffset),
    };
  }
  return {
    DateFrom: normalizeDate(true, date?.DateFrom, !!config?.toDateTimeOffset),
    DateTo: normalizeDate(false, date?.DateTo, !!config?.toDateTimeOffset),
  };
}
