import {
  InvoiceBound,
  QuerySubscribeDownloadInvoicesFromKSeFArgs,
  SubscribeDownloadInvoicesFromKSeFQueryVariables,
  SubscriptionStateEnum,
  UpoMissingInvoiceReason,
  WebsocketErrorType,
  WebSocketNotificationFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {action, computed, makeObservable, observable} from 'mobx';
import {MissingInvoiceModel} from '../../modules/earchive/models';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {FetchInvoicesFromKSeFJobRunner} from '../../services/KSeFJobRunners/FetchInvoicesFromKSeFJobRunner';
import type {DatePickerStateInterface} from '../FiltersModel/DatePickerState';
import {DatePickerState} from '../FiltersModel/DatePickerState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {NotificationDataService, NotificationServiceI} from '../../services/NotificationDataService';
import {DownloadInvoicesDataModel} from '../../services/helpers/NotificationDataParsers';
import {AlertConfig} from '../../services/helpers/AlertService';
import {
  DataTypes,
  NotificationDataResultManager,
  NotificationDataResultManagerI,
} from '../../services/NotificationDataResultManager';
import {localStorageService} from '../../modules/common/helpers/storage';
import dayjs, {Dayjs} from 'dayjs';
import {normalizeDate} from '../../modules/common/helpers/baseFilterHelpers';
import {ToastVariant} from '@symfonia/brandbook';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';
import {intl} from '../../modules/root/IntlProvider';
import {
  DownloadInvoicesModelToMap,
} from '../../modules/earchive/modules/KSeFEvents/services/KSeFEventsConverters/GetInvoicesEventConverter';

export const LOCAL_STORAGE_DATES_KEY = 'downloadInvoicesDateRanges';

export const getDateRangesLocalStorageKey = (companyId: string) => companyId + '.' + LOCAL_STORAGE_DATES_KEY;


export interface ExceptionDetailsListModel {
  ExceptionCode: string | undefined,
  ExceptionDescription: string | undefined,
}

export interface ErrorJSONResponseModel {
  ServiceCtx: string | undefined,
  ServiceCode: string | undefined,
  ServiceName: string | undefined,
  Timestamp: string | undefined,
  ReferenceNumber: string | undefined,
  ExceptionDetailList: ExceptionDetailsListModel[]
}


export interface IDownloadedInvoicesResultService extends NotificationServiceI<WebsocketNotificationType.DownloadInvoices, SubscribeDownloadInvoicesFromKSeFQueryVariables> {
  open: boolean;
  resultIsAvailable: boolean;
  errorType: WebsocketErrorType | undefined;
  hasError: boolean;
  successNotification: AlertConfig;
  modalIsActive: boolean;
  hasAlreadyDownloaded: boolean;
  defaultDateChanged: boolean;
  readonly minDate: Dayjs;
  companyId: string;

  get missingInvoices(): MissingInvoiceModel[];

  get maxDate(): Dayjs;

  get totalInvoicesCount(): number;


  setModalIsActive(isActive: boolean): void;

  setResultIsAvailable(isAvailable: boolean): this;

  setErrorType(errorType: WebsocketErrorType | undefined): this;

  resetDates(): void;

  setDefaultDateChanged(changed: boolean): void;

  setCount(type: InvoiceBound, count: number): void;

  setOpen(): void;

  resetResult(): void;
}

export class DownloadedInvoicesResultService extends NotificationDataService<WebsocketNotificationType.DownloadInvoices, SubscribeDownloadInvoicesFromKSeFQueryVariables, unknown, DownloadInvoicesModelToMap> implements IDownloadedInvoicesResultService {

  @observable
  public companyId: string = '';

  @observable.ref
  public open = false;

  @observable
  public externalCount: number | undefined = undefined;

  @observable
  public internalCount: number | undefined = undefined;

  @observable.ref
  Internal: DatePickerStateInterface | undefined = new DatePickerState(InvoiceBound.Internal, this);

  @observable.ref
  External: DatePickerStateInterface | undefined = new DatePickerState(InvoiceBound.External, this);

  @observable
  InternalEnabled: boolean | undefined;

  @observable
  ExternalEnabled: boolean | undefined;

  @observable
  public downloadType: WebsocketNotificationType | undefined = undefined;

  @observable
  public hasError: boolean = false;

  @observable
  public modalIsActive: boolean = false;

  @observable
  public defaultDateChanged: boolean = false;

  public readonly minDate: Dayjs;

  protected dataResultManager: NotificationDataResultManagerI<DownloadInvoicesDataModel>;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super(envObserver, earchiveState, () => new FetchInvoicesFromKSeFJobRunner(envObserver, earchiveState));
    makeObservable(this);
    this.minDate = dayjs(new Date(2022, 0, 1));
    this.dataResultManager = new NotificationDataResultManager<DownloadInvoicesDataModel>(this);
  }

  public get maxDate(): Dayjs {
    return dayjs(new Date()).startOf('d');
  }

  @computed
  public get missingInvoices(): MissingInvoiceModel[] {
    if (!this.currentResult || !this.currentResult.errorItems) {
      return [];
    }
    const {errorItems} = this.currentResult;
    return errorItems?.reduce<MissingInvoiceModel[]>((items, item) => {
      if (item?.kSeFNumber && item?.errorMessage) {
        const {errorMessage, kSeFNumber} = item;
        items.push({
          reason: UpoMissingInvoiceReason.Error,
          invoiceNumber: kSeFNumber,
          message: this.parseErrorFromJsonResponse(errorMessage),
        });
      }
      return items;
    }, []);
  }

  @computed.struct
  public get successNotification(): AlertConfig {

    const cancelled = this.notification?.State === SubscriptionStateEnum.Cancelled;
    const manualCancellation = cancelled ? intl.formatMessage({id: Tr.getInvoicesFromKSeFCancelled}) : '';
    if (!this.currentResult && !this.errorType) {
      return {id: Tr.getInvoicesFromKSeFSuccess, values: {count: '?', manualCancellation: manualCancellation}};
    }

    if (!this.currentResult?.errorItems?.length && !this.currentResult?.imported) {
      return {
        id: cancelled ? Tr.getInvoicesFromKSeFNoData : Tr.getInvoicesFromKSeFUpToDate,
        values: {manualCancellation: manualCancellation},
      };
    }

    if (!this.currentResult.imported) {
      return {
        id: Tr.getInvoicesFromKSeFError,
        color: ToastVariant.ERROR,
        values: {manualCancellation: manualCancellation},
      };
    }

    return {
      id: Tr.getInvoicesFromKSeFSuccess,
      values: {count: this.currentResult.imported, manualCancellation: manualCancellation},
    };
  }

  @computed
  public get currentResult(): DownloadInvoicesDataModel | null {
    return this.dataResultManager.currentResult;
  }

  @computed
  public get hasAlreadyDownloaded(): boolean {
    return !!(this.internalCount && this.internalCount > 0) || !!(this.externalCount && this.externalCount > 0);
  }

  @computed
  public get pendingNotification(): WebSocketNotificationFragment | null {
    return this.jobRunner.activeEvents[0] ?? null;
  }

  @computed
  public get totalInvoicesCount(): number {
    return this.missingInvoices.length + (this.currentResult?.successItems?.length ?? 0);
  }

  @action.bound
  public setDefaultDateChanged(changed: boolean): void {
    this.defaultDateChanged = changed;
  }

  public resetDates() {
    this.Internal?.resetDate(this.hasAlreadyDownloaded);
    this.External?.resetDate(this.hasAlreadyDownloaded);
    this.Internal?.resetValidationError(undefined);
    this.External?.resetValidationError(undefined);
  }

  @action.bound
  public setCompanyId(companyId: string) {
    this.companyId = companyId;
  }

  @action.bound
  public setOpen(): void {
    this.open = !this.open;
  }

  @action.bound
  public setArchivedResult(result: DownloadInvoicesDataModel, NotificationId: string): this {
    this.dataResultManager.setArchivedResult(result, NotificationId);
    return this;
  }

  public resetResult(...types: DataTypes[]): void {
    this.setResult(null);
    this.dataResultManager.resetResults(...types);
  }

  @action.bound
  public setDownloadType(downloadType: WebsocketNotificationType | undefined): this {
    this.downloadType = downloadType;
    return this;
  }

  public setModalIsActive(isActive: boolean): this {
    this.modalIsActive = isActive;
    if (!isActive) {
      this.resetResult();
    }
    return this;
  }

  public override checkIsReady(): boolean {
    return this.envId && this.variables.NotificationId && this.resultIsAvailable;
  }

  public handleMissedInvoices(): boolean {
    if (!this.currentResult) {
      return false;
    }

    return !!this.missingInvoices.length;
  }

  @action.bound
  setCount(type: InvoiceBound, count: number): void {
    if (type === InvoiceBound.External) {
      this.externalCount = count;
      return;
    }
    this.internalCount = count;
  }

  @action.bound
  public getLocalStorageDateRanges(companyId: string | undefined): QuerySubscribeDownloadInvoicesFromKSeFArgs | undefined {
    if (!companyId) return;
    return localStorageService.getItem<QuerySubscribeDownloadInvoicesFromKSeFArgs>(getDateRangesLocalStorageKey(companyId));
  }

  @action.bound
  public setCurrentDateRanges(dateRanges: QuerySubscribeDownloadInvoicesFromKSeFArgs | undefined) {
    if (!dateRanges) return;
    this.Internal?.setChecked(dateRanges.internalEnabled);
    this.External?.setChecked(dateRanges.externalEnabled);
    dateRanges.internal && this.Internal?.setDate({
      from: dayjs(normalizeDate(true, dateRanges.internal?.DateFrom)).toDate(),
      to: dayjs(normalizeDate(false, dateRanges.internal?.DateTo)).toDate(),
    });
    dateRanges.external && this.External?.setDate({
      from: dayjs(normalizeDate(true, dateRanges.external?.DateFrom)).toDate(),
      to: dayjs(normalizeDate(false, dateRanges.external?.DateTo)).toDate(),
    });
  }

  protected override handleEnvIdChange(envId: string | null) {
    super.handleEnvIdChange(envId);
    if (envId === null) {
      return;
    }
    this.setCompanyId(envId);
    const localStorageDates = localStorageService.getItem<QuerySubscribeDownloadInvoicesFromKSeFArgs>(getDateRangesLocalStorageKey(envId));
    if (!localStorageDates) {
      return;
    }
    const {external, internal, ...rest} = localStorageDates;
    this.setCurrentDateRanges(rest);
    this.resetDates();
  }

  protected async onSuccess(data: WebSocketNotificationFragment): Promise<void> {
    super.onSuccess(data);

    this.dataResultManager.setFetchingResult(this.result ? {...this.result} : null, this.notification);

    localStorageService.removeItem(getDateRangesLocalStorageKey(this?.companyId));

    this.handleMissedInvoices();


    if (this.result?.imported ?? 0 > 0) {
      await this.earchiveState.packageStatistics.load();
    }
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void {
    console.error({errors, error});
    this.setHasError(true);
  }

  protected override beforeFetch(): void | Promise<void> {
    this.setHasError(false);
  }


  private parseErrorFromJsonResponse(jsonString?: string): string | undefined {
    if (!jsonString) {
      return;
    }
    try {
      const errorResponse: ErrorJSONResponseModel = JSON.parse(jsonString);
      return errorResponse.ExceptionDetailList.map(e => e.ExceptionDescription)[0];
    } catch (err) {
      console.error(err);
      return jsonString;
    }
  }

  @action
  private setHasError(hasError: boolean): void {
    this.hasError = hasError;
  }

}
