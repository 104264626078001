import {DropdownListOption} from '@symfonia/brandbook';
import {InvoiceFilterType} from '../../../models';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../../../root/IntlProvider';
import {InvoicesService} from '../Invoices.service';
import {action, computed, makeObservable, observable} from 'mobx';
import {BaseModule, BaseModuleI} from '../../../../root/services/MobXServices/BaseModule';
import {BasePersistService, BasePersistServiceI} from '../../../../root/services/PersistServices/BasePersistService';
import {InvoicesRepositoryI} from '../Invoices.repository';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export interface InvoicesScopeI extends BaseModuleI {
  readonly repository: InvoicesRepositoryI;

  get invoicesVariants(): DropdownListOption[];

  get currentVariant(): [string];

  get variant(): InvoiceFilterType;

  validateVariant(current: InvoiceFilterType, variant: InvoiceFilterType): boolean;

  setVariant(variant: string): void;
}

export class InvoicesScope extends BaseModule implements InvoicesScopeI {

  private storage!: BasePersistServiceI<InvoiceFilterType>;

  constructor(private readonly invoicesService: InvoicesService) {
    super();

    this.storage = new BasePersistService<InvoiceFilterType>(this.isInternal ? 'internalInvoicesVariant' : 'externalInvoicesVariant', earchiveState.envObserver)
      .subscribe(variant => this.setVariant(variant, false), {load: true});

    makeObservable(this);

    this.reactionsManager.add(() => this._currentVariant, current => {
      this.invoicesService.tableService.setPagination({page: 1});
      this.invoicesService.repository.fetch();
    });
  }

  public get repository(): InvoicesRepositoryI {
    return this.invoicesService.repository;
  }

  @observable
  private _currentVariant: InvoiceFilterType = InvoiceFilterType.All;

  @computed
  public get currentVariant(): [string] {
    return [this._currentVariant];
  }

  @computed
  public get variant(): InvoiceFilterType {
    return this._currentVariant;
  }

  @computed
  public get invoicesVariants(): DropdownListOption[] {
    const {All, NotSent, Sent, NotDownloaded, Downloaded} = InvoiceFilterType;
    const {sent, toSend, allDocuments, toDownload, downloaded} = Tr;
    const options: { value: InvoiceFilterType, label: Tr }[] = this.invoicesService.isInternal ? [{
      label: toSend,
      value: NotSent,
    }, {
      label: sent,
      value: Sent,
    }] : [{
      label: toDownload,
      value: NotDownloaded,
    }, {
      label: downloaded,
      value: Downloaded,
    }];
    options.push({value: All, label: allDocuments});
    return options.map(({value, label}) => ({value, label: intl.formatMessage({id: label})}));
  }

  private get isInternal(): boolean {
    return this.invoicesService.isInternal;
  }

  @action.bound
  public setVariant(variant: string, persistEnabled: boolean = true): void {
    if (this.invoicesVariants.some(({value}) => value === variant)) {
      this._currentVariant = variant as InvoiceFilterType;
      persistEnabled && this.storage.save(this._currentVariant);
    }
  }

  public validateVariant(current: InvoiceFilterType, variant: InvoiceFilterType): boolean {
    const bounded = [InvoiceFilterType.Downloaded, InvoiceFilterType.NotDownloaded].includes(variant) ? this.invoicesService.isExternal : this.invoicesService.isInternal;
    return bounded ? current === variant || current === InvoiceFilterType.All : false;
  }

  protected _onMount(): void {
    this.storage.onMount();
  }

  protected _onUnmount(): void {
    this.storage.onUnmount();
  }
}
