import {FilterItems} from '@symfonia/symfonia-ui-components';
import {useIntl} from 'react-intl';
import {InvoiceBound, InvoicePostingStatus, RodzajFaktury} from '@symfonia-ksef/graphql';
import React, {FC} from 'react';
import {IFiltersState} from '../../../pages/Documents/state/IFiltersState';
import {ModulesEnum} from '../../../../common/enums/ModulesEnum';
import {observer} from 'mobx-react-lite';
import {
  Accordion,
  AccordionSize,
  DatePicker,
  DropdownListOption,
  MultipleTextInputFilter,
  MultiSelectFilter,
  NumberPicker,
} from '@symfonia/symfonia-ksef-components';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  convertToDropdownListOption,
  convertToFilterItems,
  convertToMapDropdownListOption,
  convertToMultiSelectType,
} from '../../../../common/helpers/baseFilterHelpers';
import {InputWidth} from '@symfonia/brandbook';
import {
  DateRangeFilterEnumIssueDate,
  DateRangeFilterEnumKsefIssueDate,
  RangeFilterEnumNetValue,
  RangeFilterEnumVatValue,
  RangeFilterEnumGrossValue,
} from '@symfonia-ksef/state/filters/BaseFilterState';
import {getDefaultKsefStatusesList, getDefaultPostingStatusesList} from '../../../../common/helpers/filterHelpers';
import {InvoicesFilterKeys, InvoicesFiltersState} from '../../../modules/Invoices/InvoicesFilters/InvoicesFiltersState';

export interface FiltersConfigProps {
  stateMobx: IFiltersState;
  invoiceBound: InvoiceBound;
  state: InvoicesFiltersState;
}

export const FiltersConfig: FC<FiltersConfigProps> = observer(({stateMobx, invoiceBound, state}) => {
  const intl = useIntl();

  const numbers = state.activeFilters.get(InvoicesFilterKeys.Numbers);
  const invoiceDocumentType = state.activeFilters.get(InvoicesFilterKeys.DocumentTypes);
  const issuerNames = state.activeFilters.get(InvoicesFilterKeys.IssuerNames);
  const nipsIssuer = state.activeFilters.get(InvoicesFilterKeys.IssuerNips);
  const nipsRecipient = state.activeFilters.get(InvoicesFilterKeys.RecipientNips);
  const recipientsNames = state.activeFilters.get(InvoicesFilterKeys.RecipientNames);
  const netValue = state.activeFilters.get(InvoicesFilterKeys.NetValue);
  const vatValue = state.activeFilters.get(InvoicesFilterKeys.VatValue);
  const grossValue = state.activeFilters.get(InvoicesFilterKeys.GrossValue);
  const dateOfIssue = state.activeFilters.get(InvoicesFilterKeys.DateOfIssue);
  const ksefDate = state.activeFilters.get(InvoicesFilterKeys.KsefDate);
  const ksefNumbers = state.activeFilters.get(InvoicesFilterKeys.KsefNumbers);
  const registrationNumber = state.activeFilters.get(InvoicesFilterKeys.RegistrationNumber);
  const ksefStatus = state.activeFilters.get(InvoicesFilterKeys.KSeFStatuses);
  const invoicePostingStatus = state.activeFilters.get(InvoicesFilterKeys.InvoicePostingStatuses);

  return (
    <>
      <Accordion
        group={'InvoiceNumber'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.InvoiceNumber}) +
          (numbers?.values !== undefined && numbers?.values?.length !== 0 ? ' (' + numbers?.values.length + ')' : '')
        }
        expanded={!!numbers?.values.length}
        testId="invoiceFiltersNumberAccordion"
      >
        <MultipleTextInputFilter
          itemLabel={intl.formatMessage({id: 'InvoiceNumber'})}
          filterItems={
            numbers?.pills !== undefined && numbers?.pills?.length > 0
              ? convertToFilterItems(numbers?.pills, el => ({
                  value: el.value,
                  key: el.key,
                }))
              : undefined
          }
          setFilterItems={(items?: FilterItems) => {
            state.handleSelectByValue(
              convertToMultiSelectType(items, el => ({
                value: el?.value ?? '',
                key: el?.key.toString(),
              })),
              InvoicesFilterKeys.Numbers,
            );
          }}
          itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
          addButtonLabel={intl.formatMessage({id: 'addAnother'})}
          inputWidth={InputWidth.FULL}
          testId="invoiceFiltersNumberInput"
          preventUpdateOnError={true}
          disableValidation
        />
      </Accordion>

      <Accordion
        group={'invoiceDocumentType'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.invoiceDocumentType}) +
          (invoiceDocumentType?.values !== undefined && invoiceDocumentType?.values?.length !== 0
            ? ' (' + invoiceDocumentType?.values.length + ')'
            : '')
        }
        expanded={!!state.pillsList.get(InvoicesFilterKeys.DocumentTypes)?.values}
        testId="invoiceFiltersDocumentTypeAccordion"
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(Object.values(RodzajFaktury), el => ({
            label: el,
            value: el,
          }))}
          items={convertToDropdownListOption(state.pillsList.get(InvoicesFilterKeys.DocumentTypes)?.values, el => ({
            label: el.value,
            value: el.key,
          }))}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.DocumentTypes)
          }
          testId="invoiceFiltersDocumentTypSelect"
          useSearch
        />
      </Accordion>

      {invoiceBound === InvoiceBound.External && (
        <>
          <Accordion
            group={'IssuerNames'}
            size={AccordionSize.SM}
            title={
              intl.formatMessage({id: Tr.IssuerNames}) +
              (issuerNames?.values !== undefined && issuerNames?.values?.length !== 0
                ? ' (' + issuerNames?.values.length + ')'
                : '')
            }
            expanded={!!state.pillsList.get(InvoicesFilterKeys.IssuerNames)?.values}
            testId="invoiceFiltersIssuerNamesAccordion"
          >
            <MultiSelectFilter
              options={convertToMapDropdownListOption(state.issuers, el => ({
                label: el.IssuerName,
                value: el.IssuerName,
              }))}
              items={convertToDropdownListOption(state.pillsList.get(InvoicesFilterKeys.IssuerNames)?.values, el => ({
                label: el.value,
                value: el.value,
              }))}
              onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
                state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.IssuerNames)
              }
              testId="invoiceFiltersIssuerNamesSelect"
              useSearch
            />
          </Accordion>

          <Accordion
            group={'IssuerNip'}
            size={AccordionSize.SM}
            title={
              intl.formatMessage({id: Tr.IssuerNip}) +
              (nipsIssuer?.values !== undefined && nipsIssuer?.values?.length !== 0
                ? ' (' + nipsIssuer?.values.length + ')'
                : '')
            }
            expanded={!!nipsIssuer?.values.length}
            testId="invoiceFiltersIssuerNipAccordion"
          >
            <MultipleTextInputFilter
              itemLabel={intl.formatMessage({id: Tr.IssuerNip})}
              filterItems={
                nipsIssuer?.pills !== undefined && nipsIssuer?.pills?.length > 0
                  ? convertToFilterItems(nipsIssuer?.pills, el => ({
                      value: el.value,
                      key: el.key,
                    }))
                  : undefined
              }
              setFilterItems={(items?: FilterItems) => {
                state.handleSelectByValue(
                  convertToMultiSelectType(items, el => ({
                    value: el?.value ?? '',
                    key: el?.key.toString(),
                  })),
                  InvoicesFilterKeys.IssuerNips,
                );
              }}
              itemPlaceholder={intl.formatMessage({id: Tr.SearchSpecificNumber})}
              addButtonLabel={intl.formatMessage({id: Tr.addAnother})}
              inputWidth={InputWidth.FULL}
              testId="invoiceFiltersIssuerNipInput"
              validationPattern={/^\d{1,10}$/}
              preventUpdateOnError
              setValidationError={state.setHasError}
              hasError={state.hasError}
            />
          </Accordion>
        </>
      )}

      {invoiceBound === InvoiceBound.Internal && (
        <>
          <Accordion
            group={'RecipientNames'}
            size={AccordionSize.SM}
            title={
              intl.formatMessage({id: Tr.RecipientNames}) +
              (recipientsNames?.values !== undefined && recipientsNames?.values?.length !== 0
                ? ' (' + recipientsNames?.values.length + ')'
                : '')
            }
            expanded={!!state.pillsList.get(InvoicesFilterKeys.RecipientNames)?.values.length}
            testId="invoiceFiltersRecipientNamesAccordion"
          >
            <MultiSelectFilter
              options={convertToMapDropdownListOption(state.recipients, el => ({
                label: el.RecipientName,
                value: el.RecipientName,
              }))}
              items={convertToDropdownListOption(
                state.pillsList.get(InvoicesFilterKeys.RecipientNames)?.values,
                el => ({
                  label: el.value,
                  value: el.value,
                }),
              )}
              onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
                state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.RecipientNames)
              }
              testId="invoiceFiltersRecipientNamesSelect"
              useSearch
            />
          </Accordion>

          <Accordion
            group={'RecipientNip'}
            size={AccordionSize.SM}
            title={
              intl.formatMessage({id: Tr.RecipientNip}) +
              (nipsRecipient?.values !== undefined && nipsRecipient?.values?.length !== 0
                ? ' (' + nipsRecipient?.values.length + ')'
                : '')
            }
            expanded={!!nipsRecipient?.values.length}
            testId="invoiceFiltersRecipientNipAccordion"
          >
            <MultipleTextInputFilter
              itemLabel={intl.formatMessage({id: Tr.RecipientNip})}
              filterItems={
                nipsRecipient?.pills !== undefined && nipsRecipient?.pills?.length > 0
                  ? convertToFilterItems(nipsRecipient?.pills, el => ({
                      value: el.value,
                      key: el.key,
                    }))
                  : undefined
              }
              setFilterItems={(items?: FilterItems) => {
                state.handleSelectByValue(
                  convertToMultiSelectType(items, el => ({
                    value: el?.value ?? '',
                    key: el?.key.toString(),
                  })),
                  InvoicesFilterKeys.RecipientNips,
                );
              }}
              itemPlaceholder={intl.formatMessage({id: Tr.SearchSpecificNumber})}
              addButtonLabel={intl.formatMessage({id: Tr.addAnother})}
              inputWidth={InputWidth.FULL}
              testId="invoiceFiltersRecipientNipInput"
              validationPattern={/^\d{1,10}$/}
              preventUpdateOnError
              setValidationError={state.setHasError}
              hasError={state.hasError}
            />
          </Accordion>
        </>
      )}

      <Accordion
        group={'NetValue'}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.NetValue})}
        expanded={netValue?.values[0]?.from || netValue?.values[0]?.to || netValue?.values[0].specific}
        testId="invoiceFiltersNetValueAccordion"
      >
        <NumberPicker
          setValue={v => state.setRangeFilter(InvoicesFilterKeys.NetValue, v, RangeFilterEnumNetValue)}
          labelSpecific={intl.formatMessage({id: Tr.netValueSearchAmount})}
          labelRange={intl.formatMessage({id: Tr.range})}
          setValidationError={state.setHasError}
          placeholders={{
            from: intl.formatMessage({id: Tr.from}),
            to: intl.formatMessage({id: Tr.to}),
            specific: intl.formatMessage({id: Tr.searchAmount}),
          }}
          valueFrom={netValue?.values[0]?.from ?? ''}
          valueTo={netValue?.values[0]?.to ?? ''}
          valueSpecific={netValue?.values[0]?.specific}
          testId="invoiceFiltersNetValuePicker"
        />
      </Accordion>

      <Accordion
        group={'VatValue'}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.VatValue})}
        expanded={vatValue?.values[0]?.from || vatValue?.values[0]?.to || vatValue?.values[0].specific}
        testId="invoiceFiltersVatValueAccordion"
      >
        <NumberPicker
          setValue={v => state.setRangeFilter(InvoicesFilterKeys.VatValue, v, RangeFilterEnumVatValue)}
          labelSpecific={intl.formatMessage({id: Tr.vatValueSearchAmount})}
          labelRange={intl.formatMessage({id: Tr.range})}
          setValidationError={state.setHasError}
          placeholders={{
            from: intl.formatMessage({id: Tr.from}),
            to: intl.formatMessage({id: Tr.to}),
            specific: intl.formatMessage({id: Tr.searchAmount}),
          }}
          valueFrom={vatValue?.values[0]?.from ?? ''}
          valueTo={vatValue?.values[0]?.to ?? ''}
          valueSpecific={vatValue?.values[0]?.specific}
          testId="invoiceFiltersVatValuePicker"
        />
      </Accordion>

      <Accordion
        group={'GrossValue'}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.GrossValue})}
        expanded={grossValue?.values[0]?.from || grossValue?.values[0]?.to || grossValue?.values[0].specific}
        testId="invoiceFiltersGrossValueAccordion"
      >
        <NumberPicker
          setValue={v => state.setRangeFilter(InvoicesFilterKeys.GrossValue, v, RangeFilterEnumGrossValue)}
          labelSpecific={intl.formatMessage({id: Tr.grossValueSearchAmount})}
          labelRange={intl.formatMessage({id: Tr.range})}
          setValidationError={state.setHasError}
          placeholders={{
            from: intl.formatMessage({id: Tr.from}),
            to: intl.formatMessage({id: Tr.to}),
            specific: intl.formatMessage({id: Tr.searchAmount}),
          }}
          valueFrom={grossValue?.values[0]?.from ?? ''}
          valueTo={grossValue?.values[0]?.to ?? ''}
          valueSpecific={grossValue?.values[0]?.specific}
          testId="invoiceFiltersGrossValuePicker"
        />
      </Accordion>

      {invoiceBound === InvoiceBound.Internal && (
        <Accordion
          group={'DateOfIssue'}
          size={AccordionSize.SM}
          title={intl.formatMessage({id: Tr.DateOfIssue})}
          expanded={dateOfIssue?.values[0]?.from || dateOfIssue?.values[0]?.to || dateOfIssue?.values[0]?.specific}
          testId="invoiceFiltersDateOfIssueAccordion"
        >
          <DatePicker
            startDate={dateOfIssue?.values[0]?.from ?? undefined}
            endDate={dateOfIssue?.values[0]?.to ?? undefined}
            specificDate={dateOfIssue?.values[0].specific ?? undefined}
            setDate={v => state.setRangeFilter(InvoicesFilterKeys.DateOfIssue, v, DateRangeFilterEnumIssueDate)}
            singleDateLabel={intl.formatMessage({id: Tr.dateLabel})}
            rangeStartDateLabel={intl.formatMessage({id: Tr.dateFromTo})}
            singleDateInputLabel={intl.formatMessage({id: Tr.selectSpecificDate})}
            rangeDateInputLabel={intl.formatMessage({id: Tr.selectDate})}
            rangeDateFromLabel={intl.formatMessage({id: Tr.From})}
            rangeDateToLabel={intl.formatMessage({id: Tr.To})}
            setValidationError={state.setHasError}
            testId="invoiceFiltersDateOfIssueDatePicker"
          />
        </Accordion>
      )}

      <Accordion
        group={'ksefDate'}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.invoiceKsefIssueDate})}
        expanded={ksefDate?.values[0].from || ksefDate?.values[0].to || ksefDate?.values[0].specific}
        testId="invoiceFilterKsefDateAccordion"
      >
        <DatePicker
          startDate={ksefDate?.values[0]?.from ?? undefined}
          endDate={ksefDate?.values[0]?.to ?? undefined}
          specificDate={ksefDate?.values[0].specific ?? undefined}
          setDate={v => state.setRangeFilter(InvoicesFilterKeys.KsefDate, v, DateRangeFilterEnumKsefIssueDate)}
          singleDateLabel={intl.formatMessage({id: Tr.dateLabel})}
          rangeStartDateLabel={intl.formatMessage({id: Tr.dateFromTo})}
          singleDateInputLabel={intl.formatMessage({id: Tr.selectSpecificDate})}
          rangeDateInputLabel={intl.formatMessage({id: Tr.selectDate})}
          rangeDateFromLabel={intl.formatMessage({id: Tr.From})}
          rangeDateToLabel={intl.formatMessage({id: Tr.To})}
          setValidationError={state.setHasError}
          testId="invoiceFiltersKsefDatePicker"
        />
      </Accordion>

      <Accordion
        group={'KsefNumbers'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.KsefNumber}) +
          (ksefNumbers?.values !== undefined && ksefNumbers?.values?.length !== 0
            ? ' (' + ksefNumbers?.values.length + ')'
            : '')
        }
        expanded={!!ksefNumbers?.values.length}
        testId="invoiceFilterKsefNumbersAccordion"
      >
        <MultipleTextInputFilter
          itemLabel={intl.formatMessage({id: 'number'})}
          filterItems={
            ksefNumbers?.pills !== undefined && ksefNumbers?.pills?.length > 0
              ? convertToFilterItems(ksefNumbers?.pills, el => ({
                  value: el.value,
                  key: el.key,
                }))
              : undefined
          }
          setFilterItems={(items?: FilterItems) => {
            state.handleSelectByValue(
              convertToMultiSelectType(items, el => ({
                value: el?.value ?? '',
                key: el?.key.toString(),
              })),
              InvoicesFilterKeys.KsefNumbers,
            );
          }}
          itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
          addButtonLabel={intl.formatMessage({id: 'addAnother'})}
          inputWidth={InputWidth.FULL}
          testId="invoiceFiltersKsefNumbersInput"
          preventUpdateOnError={true}
          disableValidation
        />
      </Accordion>
      {invoiceBound === InvoiceBound.Internal && (
        <Accordion
          group={'KSeFStatuses'}
          size={AccordionSize.SM}
          title={
            intl.formatMessage({id: Tr.invoiceKsefStatus}) +
            (ksefStatus?.values !== undefined && ksefStatus?.values?.length !== 0
              ? ' (' + ksefStatus?.values.length + ')'
              : '')
          }
          expanded={!!state.pillsList.get(InvoicesFilterKeys.KSeFStatuses)?.values}
          testId="invoiceFilterKsefStatusesAccordion"
        >
          <MultiSelectFilter
            options={convertToMapDropdownListOption(getDefaultKsefStatusesList(), el => ({
              label: el.value,
              value: el.key,
            }))}
            items={convertToDropdownListOption(state.pillsList.get(InvoicesFilterKeys.KSeFStatuses)?.values, el => ({
              label: el.value,
              value: el.key,
            }))}
            onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
              state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.KSeFStatuses)
            }
            testId="invoiceFiltersKsefStatusesSelect"
            useSearch
          />
        </Accordion>
      )}
      {stateMobx.invoicePageState.documentsState.earchiveState.moduleStatusStore.getModule(ModulesEnum.autoPosting)
        ?.isModulePurchased && (
        <Accordion
          group={'registrationNumber'}
          size={AccordionSize.SM}
          title={
            intl.formatMessage({id: Tr.registrationNumber}) +
            (registrationNumber?.values !== undefined && registrationNumber?.values?.length !== 0
              ? ' (' + registrationNumber?.values.length + ')'
              : '')
          }
          expanded={!!registrationNumber?.values.length}
          testId="invoiceFiltersRegistrationNumberAccordion"
        >
          <MultipleTextInputFilter
            itemLabel={intl.formatMessage({id: 'number'})}
            filterItems={
              registrationNumber?.pills !== undefined && registrationNumber?.pills?.length > 0
                ? convertToFilterItems(registrationNumber?.pills, el => ({
                    value: el.value,
                    key: el.key,
                  }))
                : undefined
            }
            setFilterItems={(items?: FilterItems) => {
              state.handleSelectByValue(
                convertToMultiSelectType(items, el => ({
                  value: el?.value ?? '',
                  key: el?.key.toString(),
                })),
                InvoicesFilterKeys.RegistrationNumber,
              );
            }}
            itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
            addButtonLabel={intl.formatMessage({id: 'addAnother'})}
            inputWidth={InputWidth.FULL}
            testId="invoiceFiltersRegistrationNumberInput"
          />
        </Accordion>
      )}
      {stateMobx.invoicePageState.documentsState.earchiveState.moduleStatusStore.getModule(ModulesEnum.autoPosting)
        ?.isModulePurchased && (
        <Accordion
          group={'invoicePostingStatus'}
          size={AccordionSize.SM}
          title={
            intl.formatMessage({id: Tr.invoicePostingStatus}) +
            (invoicePostingStatus?.values !== undefined && invoicePostingStatus?.values?.length !== 0
              ? ' (' + invoicePostingStatus?.values.length + ')'
              : '')
          }
          expanded={!!state.pillsList.get(InvoicesFilterKeys.InvoicePostingStatuses)?.values}
          testId="invoiceFiltersInvoicePostingStatusAccordion"
        >
          <MultiSelectFilter
            options={convertToMapDropdownListOption(
              getDefaultPostingStatusesList().filter(el => el.key !== 'UNDEFINED'),
              el => ({
                label: el.value,
                value: el.key,
              }),
            )}
            items={convertToDropdownListOption(
              state.pillsList.get(InvoicesFilterKeys.InvoicePostingStatuses)?.values,
              el => ({
                label: el.value,
                value: el.key,
              }),
            )}
            onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
              state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.InvoicePostingStatuses)
            }
            testId="invoiceFiltersInvoicePostingStatusSelect"
          />
        </Accordion>
      )}
    </>
  );
});
