import {Tr} from '../../../../locales/translationKeys';
import {FormattedMessage} from 'react-intl';
import {PageMessage} from '../../../common';

export const CompanyNotFound = () => {
  return (
    <PageMessage
      title={<FormattedMessage id={Tr.companyNotFoundTitle}/>}
      description={<FormattedMessage id={Tr.companyNotFoundDesc}/>}
    />
  );
};
