import {observer} from 'mobx-react-lite';
import {MissingInvoicesModal} from './MissingInvoices';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {getUpoResultService} from '@symfonia-ksef/state/rootRepository';

export const UPOMissedInvoicesModal = observer(() => {
  const intl = useIntl();
  return <MissingInvoicesModal
    title={intl.formatMessage({id: Tr.missingUpoTitle})}
    content={(missingInvoicesCount, allInvoicesCount) => <FormattedMessage id={Tr.missingUpoContent}
                                                                           values={{
                                                                             br: <br/>,
                                                                             from: missingInvoicesCount,
                                                                             to: allInvoicesCount,
                                                                           }}/>}
    missingInvoices={getUpoResultService.missingItems}
    requestedInvoicesCount={getUpoResultService.requestedInvoicesCount}
    open={getUpoResultService.modalIsActive}
    onClose={() => getUpoResultService.setModalIsActive(false)}
  />;
});
