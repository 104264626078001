import {ExtendedTableServiceI} from '../../../../root/services/TableServices/ExtendedTableService';
import {
  GetContractorsQuery,
  GetContractorsQueryVariables,
  WhiteListModelFragment,
} from '@symfonia-ksef/graphql';
import {intl} from '../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {DefaultDataSourceHandler} from '../../../../root/services/TableServices/DataSourceTableService';
import React from 'react';
import {Columns} from '../../../../root/services/TableServices/BaseTableService';
import {SupplierTypeRenderer} from '../../../../common/components/GridRenderers/SupplierTypeRenderer/SupplierTypeRenderer';
import {TagSize, Tag, TagColor} from '@symfonia/brandbook';
import dayjs from 'dayjs';
import {ContractorAccountsProps, ReactSetStateType} from '../../../hooks/useContractorsTableConfiguration';
import {ContractorModalProps} from '../../../hooks/useInvoicesTableConfiguration';


export type ContractorsRow = {
  Id: string,
  Identifier?: string,
  Name?: string | undefined,
  SupplierType: number,
  WhiteListStatus?: WhiteListModelFragment | undefined,
  Account: {
    name: string | undefined,
    whiteList: WhiteListModelFragment | undefined,
    accountNumber: string | undefined
    restAccountsCount: number | undefined;
  }
  AccountStatus: {
    date: string | undefined;
    isWhiteListed: boolean | undefined;
  },
};

export type ContractorsColumns = Columns<ContractorsRow>

export type ContractorsTableI = ExtendedTableServiceI<ContractorsRow, 'GetContractors', GetContractorsQuery, GetContractorsQueryVariables>

export class ContractorsDataSourceHandler extends DefaultDataSourceHandler<'GetContractors', GetContractorsQuery, ContractorsRow, GetContractorsQueryVariables> {
  constructor(public repository: any) {
    super(repository);
  }

  public dataMapper(data: GetContractorsQuery['GetContractors'] | null) {
    if (data?.Items) {
      return data.Items.map(contractor => ({
        ...contractor,
        Account: {
          name: contractor.Name,
          whiteList: contractor.WhiteListStatus,
          accountNumber: contractor.WhiteListStatus?.Accounts?.[0]?.Account,
          restAccountsCount: contractor.WhiteListStatus?.Accounts?.length,
        },
        AccountStatus: {
          date: contractor.WhiteListStatus?.Accounts?.[0]?.Date || contractor.CreatedAt,
          isWhiteListed: contractor.WhiteListStatus?.Accounts?.[0]?.IsWhiteListed,
        },
      }));
    }
    return [];
  }

  public override getTotalCount(data: GetContractorsQuery['GetContractors']): number {
    return data.TotalCount;
  }
}

export const contractorsTableKeysFactory = (row: ContractorsRow): string => row.Id;

export const contractorsInitialColumnsFactory = (config: {
  setContractorAccountsModalProps: ReactSetStateType<ContractorAccountsProps>
  setContractorModalProps: ReactSetStateType<ContractorModalProps>
}): ContractorsColumns => ({
  Name: {
    header: intl.formatMessage({id: Tr.contractor}),
    width: 'w-[100px]',
    sortable: true,
    order: 1,
    cell: (row) => (
      <span className="underline cursor-pointer" onClick={() => config.setContractorModalProps({
        contractorName: row.Name ?? '',
        contractorNip: row.Identifier ?? '',
        modalIsActive: true,
        whiteListStatus: row.WhiteListStatus,
        supplierType: row.SupplierType,
      })}>{row.Name}</span>
    ),
    cellAsTooltip: true,
    ellipsisEnabled: true,
  },
  Identifier: {
    header: intl.formatMessage({id: Tr.ContractorIdentifier}),
    order: 2,
    sortable: true,
    width: 'w-[100px]',
    cell: (row) => row.Identifier,
  },
  SupplierType: {
    header: intl.formatMessage({id: Tr.ContractorTypeSwitchesHeader}),
    width: 'w-[160px]',
    sortable: true,
    order: 3,
    cell: (row) => <SupplierTypeRenderer supplierType={row.SupplierType}/>,
  },
  Account: {
    header: intl.formatMessage({id: Tr.singleBankAccount}),
    width: 'w-[400px]',
    sortable: false,
    order: 4,
    cell: (row) => {
      return (
        row?.Account?.accountNumber ?
        <div className="flex gap-2 items-center">
          <span className="text-md">{row?.Account.accountNumber}</span>
          {row?.Account.restAccountsCount && row?.Account.restAccountsCount > 1 &&
          <span onClick={() => config.setContractorAccountsModalProps(
            {
              modalIsActive: true,
              contractorName: row.Account.name,
              whiteListStatus: row.Account.whiteList
            })}
            className="text-primary-500 font-bold cursor-pointer">+ {row?.Account.restAccountsCount - 1}
          </span>}
        </div>
        :
        null
      );
    },
    ellipsisEnabled: true,
  },
  AccountStatus: {
    header: intl.formatMessage({id: Tr.accountStatus}),
    width: 'w-[260px]',
    sortable: false,
    order: 5,
    cell: (row) => {
      const isWhiteListed = row?.AccountStatus.isWhiteListed;
      return (
        <div className="flex gap-2">
          <span>
            {isWhiteListed !== undefined ?
              isWhiteListed ? 
              <Tag size={TagSize.SM} color={TagColor.GREEN_LIGHT} text={intl.formatMessage({id: Tr.isWhiteListed})}/>
              :
              <Tag size={TagSize.SM} color={TagColor.RED_LIGHT} text={intl.formatMessage({id: Tr.isNotWhiteListed})}/>
              : <Tag size={TagSize.SM} color={TagColor.GREY} text={intl.formatMessage({id: Tr.noAccounts})}/>
            }
          </span>
          <span className="lowercase text-sm">{intl.formatMessage({id: Tr.contractorAccountsNumbersListDateHeader})}: {dayjs(row?.AccountStatus.date).format('YYYY-MM-DD')}</span>
        </div>
      );
    },
  },
});
