import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {MissingInvoicesModal} from './MissingInvoices';
import {deleteInvoiceService} from '../../../../../state/rootRepository';

export const DeletedMissedInvoicesModal = observer(() => {
  const totalCount = deleteInvoiceService.currentResult?.invoicesId.length;
  const missingInvoices = deleteInvoiceService.missingInvoices;

  return <MissingInvoicesModal
    title={<FormattedMessage
      id={Tr.deletedMissedInvoicesModalTitle}/>}
    content={<FormattedMessage
      id={Tr.deletedMissedInvoicesModalContent} values={{
      range: <span
        className="font-bold">{missingInvoices?.length ?? undefined} z {totalCount}</span>,
    }}/>}
    open={deleteInvoiceService.modalIsActive}
    missingInvoices={missingInvoices ?? undefined}
    onClose={() => {
        deleteInvoiceService.closeModalRemoveNotification();
    }}
  />;
});

