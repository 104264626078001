import {ApolloError} from '@apollo/client';
import {ToastVariant} from '@symfonia/brandbook';
import {action, makeObservable, observable} from 'mobx';
import {ReactNode} from 'react';
import {EArchiveState} from './EarchiveState';
import {v4} from 'uuid';
import {BaseModule} from '../../modules/root/services/MobXServices/BaseModule';

export enum AlertType {
  Default,
  WsNotification,
}

export interface GlobalAlertModel {
  uid: string;
  content: ReactNode | string;
  color: ToastVariant;
  error?: ApolloError | undefined;
  onClick?: () => void;
  onClose?: () => void;
  closeOnClick?: boolean;
  type: AlertType;
  actions?: { onClick: () => void; title: string };
  timestamp?: number;
}

export interface MessageModel {
  uid: any;
  content: ReactNode;
  color: ToastVariant;
  error: ApolloError | undefined;
}

type AddAlertOptions = {
  displayDuration?: number | null;
  omitIfHasTheSameAlert?: boolean;
  moveToTopIfAlreadyExist?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  closeOnClick?: boolean;
  type?: AlertType;
  preventAutoOnClose?: boolean;
  actions?: GlobalAlertModel['actions'];
  timestamp?: number;
};

export class AlertsState extends BaseModule {
  @observable alertsTimeouts: Record<string, ReturnType<typeof setTimeout>>;

  @observable alerts: GlobalAlertModel[];

  @observable messages: MessageModel[];

  constructor(private globalState: EArchiveState) {
    super();
    makeObservable(this);
    this.alertsTimeouts = {};
    this.alerts = [];
    this.messages = [];
  }

  @action.bound
  public addAlertTimeout(alertId: string, callback: () => void, timeout: number): void {
    this.alertsTimeouts[alertId] = setTimeout(callback, timeout);
  }

  @action.bound
  public removeAlertTimeout(alertId: string): boolean {
    if (alertId in this.alertsTimeouts) {
      clearTimeout(this.alertsTimeouts[alertId]);
      delete this.alertsTimeouts[alertId];
      return true;
    }
    return false;
  }

  @action.bound
  public addAlert(content: ReactNode | string, color: ToastVariant, opt?: AddAlertOptions) {
    const type = opt?.type ?? AlertType.Default;
    const {omitIfHasTheSameAlert, displayDuration, moveToTopIfAlreadyExist, onClose, ...rest} = opt ?? {};
    const omitAdding = !!opt?.omitIfHasTheSameAlert && !!this.alerts.find(alert => alert.content === content);
    // && this.hasSameAlertAs(content);
    if (omitAdding) {
      return;
    }
    if (opt?.moveToTopIfAlreadyExist) {
      const {success, id} = this.findAlertAndMoveToTop(content, color, opt);
      if (success) {
        return id;
      }
    }
    const requestLimitAlert = this.alerts.find(
      el => el.content === 'Osiągnięto limit zapytań w Krajowym Systemie e-Faktur dla tej operacji',
    );
    if (requestLimitAlert) {
      return;
    }
    const ifOffline = !window.navigator.onLine;
    const alert = {uid: v4(), content, color, onClose, ...rest, type};
    if (ifOffline) {
      const uid = v4();
      this.alerts.push({
        uid,
        content: 'Brak połączenia internetowego.',
        color: ToastVariant.ERROR,
        onClose,
        ...rest,
        type,
      });
      this.timeoutForAlert(uid, opt?.displayDuration ?? 5000, onClose);
    }
    this.alerts.push(alert);
    const viewport = this.globalState.layoutState.viewport;
    const viewportAlertsLimit = viewport.isDesktop || viewport.isLargeDesktop ? 3 : 1;
    if (this.alerts.length > viewportAlertsLimit) {
      this.removeAlert(this.alerts[0].uid);
    }
    if (opt?.displayDuration) {
      this.timeoutForAlert(alert.uid, opt.displayDuration, opt.preventAutoOnClose ? undefined : onClose);
    }
    return alert.uid;
  }

  @action.bound
  public addSnackBarMessage(content: ReactNode, color: ToastVariant, error?: ApolloError | undefined) {
    const message = {uid: v4(), content, color, error};
    this.messages = [message];
    this.timeoutForAlert(message.uid, 6000);
  }

  @action.bound
  public removeAlert(alertId: string) {
    const alert = this.alerts.find(el => el.uid === alertId);
    if (alert) {
      this.removeAlertTimeout(alert.uid);
      this.alerts = this.alerts.filter(el => el.uid !== alertId);
    }
  }

  @action.bound
  public clearAlerts(type?: AlertType) {
    for (const alert of this.alerts) {
      if (!type || alert.type === type) {
        this.removeAlertTimeout(alert.uid);
      }
    }
    this.alerts = type ? this.alerts.filter(alert => alert.type !== type) : [];
  }

  @action.bound
  public findAlertAndMoveToTop(content: ReactNode, color: ToastVariant, opt: AddAlertOptions) {
    const foundAlert = this.alerts.find(alert => alert.content === content);
    if (!foundAlert) {
      return {success: false};
    }
    this.alerts = this.alerts.filter(({uid}) => foundAlert.uid !== uid);
    this.alerts.push({
      uid: foundAlert.uid,
      content,
      color: color ?? foundAlert.color,
      type: opt?.type ?? AlertType.Default,
    });

    opt?.displayDuration &&
    this.timeoutForAlert(foundAlert.uid, opt.displayDuration, opt?.preventAutoOnClose ? undefined : opt?.onClose);
    return {success: true, id: foundAlert.uid};
  }

  @action.bound
  public removeSnackBarMessage(messageId: string) {
    const filteredMessage = this.messages.find(el => el.uid === messageId);
    if (filteredMessage) {
      this.messages = this.messages.filter(el => el.uid !== messageId);
    }
  }

  @action.bound
  public timeoutForAlert(uid: string, displayDuration: number, onClose: undefined | (() => void) = undefined) {
    this.addAlertTimeout(
      uid,
      () => {
        this.removeAlert(uid);
        this.removeSnackBarMessage(uid);
        onClose?.();
      },
      displayDuration,
    );
  }

  @action.bound
  public hasSameAlertAs(content: ReactNode | string): boolean {
    return !!this.alerts.find(alert => alert.content === content);
  }
}
