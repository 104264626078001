import {Tr} from '@symfonia-ksef/locales/keys';
import React, {createElement, ReactNode} from 'react';
import {DateRenderer} from '../../../../common';
import {intl} from '../../../../root/IntlProvider';
import {Badge, BadgeSize, IconSvg, TableSortDirection, Tag, TagColor, TagSize} from '@symfonia/brandbook';
import {Button} from '@mui/material';
import {Columns} from '../../../../root/services/TableServices/BaseTableService';
import {WsEventsRepository, WsEventsVariables} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsEventsRepository';
import {ClickableConfig, DEFAULT_CONFIG} from '../../../../root/services/TableServices/ClickableTableService';
import classnames from 'classnames';
import {DataSourceHandlerI, GetVariablesParams} from '../../../../root/services/TableServices/DataSourceTableService';
import {GetWebsocketNotificationQuery, WebSocketNotificationFragment} from '@symfonia-ksef/graphql';
import {KSeFEventConverterFactory} from './KSeFEventsConverters/KSeFEventsConvertersManager';

export type KSeFEventsRow = {
  Date: number,
  OperationType: string,
  Type: Tr | undefined,
  Content: ReactNode,
  _Action: (() => void | Promise<void>) | undefined,
  _ActionElement?: { icon: string, tooltipText: Tr } | undefined,
  _HasError: boolean,
  Visited: boolean,
  Id: string
}

export type KSeFEventsColumns = Columns<KSeFEventsRow>

export const createClickableRowConfig = (repository: WsEventsRepository): Partial<ClickableConfig<KSeFEventsRow>> => ({
  focusedRowClassName: undefined,
  rowClassName: (row) => classnames(DEFAULT_CONFIG.rowClassName, {'font-bold': !row.Visited}),
  onClick: (focusedRow, _, event) => {
    event?.stopPropagation();
    !focusedRow.Visited && repository.setMessageRead(focusedRow.Id);
  },
});

export const createColumns = (repository: WsEventsRepository): KSeFEventsColumns => ({
  Date: {
    cell: (row) => <span className="flex items-center">
      {!row.Visited && <Badge text="" size={BadgeSize.SM} className="mr-[4px] bg-green-500"/>}
      <DateRenderer value={row.Date} withTime/>
    </span>,
    header: intl.formatMessage({id: Tr.eventDate}),
    width: 'w-[180px]',
    order: 1,
    isObserver: true,
    sortable: true,
    sortingCompare: (a, b, direction) => direction === TableSortDirection.ASC ? a.Date - b.Date : a.Date + b.Date,
  },
  Type: {
    cell: (row) => <Tag
      text={row.Type ? intl.formatMessage({id: row.Type}) : ''}
      lIcon={row._HasError ? IconSvg.WARNING_CIRCLE : undefined}
      color={row._HasError ? TagColor.RED_LIGHT : TagColor.GREEN_LIGHT}
      size={TagSize.SM}
    />,
    header: intl.formatMessage({id: Tr.notificationType}),
    width: 'w-[160px]',
    order: 2,
    isObserver: true,
  },
  OperationType: {
    cell: (row) => row.OperationType,
    header: intl.formatMessage({id: Tr.operationType}),
    width: 'w-[180px]',
    order: 3,
    isObserver: true,
    ellipsisEnabled: true,
    cellAsTooltip: true,
  },
  Content: {
    cell: (row) => {
      return row.Content;
    },
    header: intl.formatMessage({id: Tr.message}),
    width: 'w-[500px]',
    order: 4,
    isObserver: true,
    ellipsisEnabled: true,
    cellAsTooltip: true,
  },
  Id: {
    cell: (row) => {
      return createElement(Button, {
        onClick: async (e) => {
          e.stopPropagation();
          repository.setMessageRead(row.Id);
          await row._Action?.();
        },
      }, createElement('img', {src: row._ActionElement?.icon}));
    },
    header: intl.formatMessage({id: Tr.actions}),
    width: 'w-[70px]',
    order: 5,
    isObserver: true,
    asAction: true,
  },
});

export class KSeFEventsTableDataSourceComponents implements DataSourceHandlerI<'GetWebsocketNotificationsList', GetWebsocketNotificationQuery, WsEventsVariables, KSeFEventsRow> {
  constructor(public readonly repository: WsEventsRepository) {

  }

  public prepareVariablesConfig(params: GetVariablesParams) {
    return {};
  }

  public getTotalCount(data: GetWebsocketNotificationQuery['GetWebsocketNotificationsList']) {
    return data?.WebsocketNotification?.filter?.(event => Boolean(event))?.length ?? 0;
  }

  public dataMapper(data: GetWebsocketNotificationQuery['GetWebsocketNotificationsList'] | null) {
    if (!data?.WebsocketNotification) {
      return [];
    }
    return data.WebsocketNotification
      .filter(event => Boolean(event))
      .map<KSeFEventsRow>(event => {
        const e = event as WebSocketNotificationFragment;
        const converter = KSeFEventConverterFactory.create(e);
        return {
          Date: new Date(e.Timestamp).getTime(),
          Content: converter?.notification,
          Type: converter?.status,
          OperationType: converter?.typeName ?? '',
          Id: e.NotificationId,
          _ActionElement: converter?.actionElement,
          _Action: () => converter?.action?.(),
          Visited: e.Visited,
          _HasError: !!converter?.model.hasError,
        };
      });
  }
}
