import * as React from 'react';
import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {SelectionI} from '../../../../root/services/TableServices/ExtendedTableService';
import {MultipleActionsBar} from '../../../../common/components/MultipleActionsBar/MultipleActionsBar';
import {ContractorsFiltersState} from './ContractorsFiltersState';
import {ContractorsRow} from './ContractorsTableConfig';
import {useContractorsGroupActions} from './useContractorsGroupActions';
import {ContractorsSwitchTypeModal} from './ContractorsSwitchTypeModal';
import {useContractorsTableMethods} from '../../../hooks/useContractorsTableMethods';

export const GroupContractorsActionPanel: FC<{
  filtersState: ContractorsFiltersState,
  selectionState: SelectionI<ContractorsRow>,
	refreshData: () => void;
}> = observer(({filtersState, selectionState, refreshData}) => {

	const {
		updateSupplierType,
		groupSwitchSupplierTypeModalProps,
		setGroupSwitchSupplierTypeModalProps
	} = useContractorsTableMethods();
  
  const actions = useContractorsGroupActions({
    selectionState,
    setSwitchTypeModal: setGroupSwitchSupplierTypeModalProps,
    filters: filtersState,
  });

  if (selectionState.selected.length <= 1) {
    return null;
  }

  return <>
    <MultipleActionsBar selectionState={selectionState} actions={actions}/>
    {groupSwitchSupplierTypeModalProps && 
				<ContractorsSwitchTypeModal
					confirm={(supplierType) => {
						updateSupplierType(groupSwitchSupplierTypeModalProps.contractorNips, supplierType, refreshData);
						setGroupSwitchSupplierTypeModalProps(undefined);
					}}
					close={() => setGroupSwitchSupplierTypeModalProps(undefined)}
					modalProps={groupSwitchSupplierTypeModalProps}
				/>
		}
  </>;
});