import {Tr} from '@symfonia-ksef/locales/keys';
import {FC, useCallback, useEffect, useState} from 'react';
import {GetContractorsDocument, GetContractorsQuery, OrderDirection, WhiteListModelFragment} from '@symfonia-ksef/graphql';
import {ContractorAccountsNumbersList} from './ContractorAccountsNumbersList';
import {ContractorTypeSwitches} from './ContractorTypeSwitches';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';
import {intl} from '../../../../root/IntlProvider';
import {l} from '../../../../logger/loggerInstance';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {ButtonPrimary, ButtonPrimarySize, ButtonPrimaryVariant, ButtonPrimaryWidth, ButtonSecondary, ButtonSecondarySize, ButtonSecondaryVariant, ButtonSecondaryWidth, ButtonTertiary, IconColor, IconSize, IconSvg, IconVariant, Separator, ToastVariant} from '@symfonia/brandbook';
import {DialogModal} from '@symfonia/symfonia-ksef-components';
import {ContractorModalProps} from '../../../hooks/useInvoicesTableConfiguration';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {AppRoutes} from '../../../../root/Routes';

export const ContractorsModal: FC<{
  confirm: (supplierType: number) => void;
  close: () => void;
  modalProps: ContractorModalProps;
}> = ({confirm, close, modalProps}) => {
  const [supplierType, setSupplierType] = useState<number>();
  const [contractorName, setContractorName] = useState<string>();
  const [whitelistStatus, setWhitelistStatus] = useState<WhiteListModelFragment>();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const isNotContractorPage = !location.pathname.includes('contractors');
  const ksefContractorsRoute = AppRoutes.eArchive.address + '/' + params.tenantId + '/company/' + params.companyId + AppRoutes.ksefContractors.address;
  const addAlert = earchiveState.alertsState.addAlert;
  const companyEnvironmentId = earchiveState.company.envId;

  const getContractor = useCallback(
    async () => {
      const response = await apolloClient.query<GetContractorsQuery>({
        query: GetContractorsDocument,
        context: {envId: companyEnvironmentId},
        variables: {
          Skip: 0,
          Take: 1,
          SearchText: '',
          Order: {
            Direction: OrderDirection.Asc,
            FieldName: 'Identifier',
          },
          ContractorFiltersFields: {
            Identifier: modalProps.contractorNip,
            InvoiceId: modalProps.invoiceId,
          },
        },
      });
      if (response.error) {
        l.error('Error when loading contractor data', undefined, response.errors);
        addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR, {omitIfHasTheSameAlert: true});
        close();
      }

      setSupplierType((response.data?.GetContractors?.Items && response.data?.GetContractors?.Items?.length !== 0) ? response.data?.GetContractors?.Items[0].SupplierType : 0);
      setContractorName((response.data?.GetContractors?.Items && response.data?.GetContractors?.Items?.length !== 0) ? response.data?.GetContractors?.Items[0].Name : modalProps.contractorName);
      setWhitelistStatus((response.data?.GetContractors?.Items && response.data?.GetContractors?.Items?.length !== 0) ? response.data?.GetContractors?.Items[0].WhiteListStatus : undefined);
    },
    [addAlert, apolloClient, companyEnvironmentId, modalProps],
  );

  const setWhiteListData = useCallback(() => {
    setSupplierType(modalProps.supplierType);
    setContractorName(modalProps.contractorName);
    setWhitelistStatus(modalProps.whiteListStatus);
  }, [modalProps]);

  useEffect(() => {
    !modalProps.whiteListStatus && modalProps.modalIsActive ? getContractor() : setWhiteListData();
  }, [getContractor, modalProps, setWhiteListData]);

  const onConfirm = () => {
    confirm(supplierType ?? 0);
  };
  
  return (
    <DialogModal className={'min-w-[500px]'} open={modalProps.modalIsActive} onCancel={close} setMaxWidth={false} testId={`${ modalProps.contractorNip }ContractorModal`}
      title={<div>{contractorName}</div>}>
      <span>Nip: {modalProps.contractorNip}</span>
      <Separator className='my-[15px]'/>
      <ContractorTypeSwitches supplierType={supplierType ?? 0} setSupplierType={setSupplierType} testId={`${ modalProps.contractorNip }ContractorTypeSwitches`}/>
      <Separator className='my-[20px]'/>
      <ContractorAccountsNumbersList model={whitelistStatus}/>
      <div className="flex flex-row justify-between items-center w-full px-0 mt-[20px]">
        {isNotContractorPage && 
          <ButtonTertiary onClick={() => navigate(ksefContractorsRoute)} text={intl.formatMessage({id: Tr.ContractorsModalGoToContractorsList})} className="mr-auto"/>
        }
        <div className="ml-auto">
          <ButtonSecondary text={intl.formatMessage({id: Tr.cancelButton})} onClick={close} variant={ButtonSecondaryVariant.GREEN} size={ButtonSecondarySize.MD} width={ButtonSecondaryWidth.INITIAL} className='mr-[10px]' testId={`${ modalProps.contractorNip }ContractorModalCancelButton`}/>
          <ButtonPrimary text={intl.formatMessage({id: Tr.save})} onClick={onConfirm} variant={ButtonPrimaryVariant.GREEN} size={ButtonPrimarySize.MD} width={ButtonPrimaryWidth.INITIAL} testId={`${ modalProps.contractorNip }ContractorModalConfirmButton`}/>
        </div>
      </div>
    </DialogModal>
  );
};
