import {observer} from 'mobx-react-lite';
import {MissingInvoicesModal} from './NotificationModals/MissingInvoices/MissingInvoices';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '../../../locales/translationKeys';
import {useCallback, useMemo} from 'react';
import {MissingInvoiceModel} from '../models';
import {getUpoResultService} from '../../../state/rootRepository';
import {UpoMissingInvoiceReason} from '@symfonia-ksef/graphql';
import {getNotificationTypeName} from '../../../services/SubscriptionEventsService';

export const GettingUPOModal = observer(() => {

  const {jobRunner: {hasToWait, pendingProcesses}} = getUpoResultService;

  const processes = useMemo<MissingInvoiceModel[]>(() => {
    return pendingProcesses.map(process => ({
      message: getNotificationTypeName({Type: process.type}),
      reason: UpoMissingInvoiceReason.Error,
    }));
  }, [pendingProcesses]);

  const onClose = useCallback(() => getUpoResultService.jobRunner.setHasToWait(false, {reset: true}), []);
  const intl = useIntl();
  const onResolve = useCallback(async () => await getUpoResultService.jobRunner.run(), []);

  return <MissingInvoicesModal
    title={intl.formatMessage({id:Tr.sessionIsNotTerminated})}
    content={<FormattedMessage id={Tr.sessionIsNotTerminatedMessage}/>}
    tableTitle={intl.formatMessage({id:Tr.ongoingSessionProcesses})}
    showButtonLabel={intl.formatMessage({id:Tr.showOngoingSessionProcesses})}
    resolveButtonLabel={intl.formatMessage({id:Tr.waitAndDownload})}
    rejectButtonLabel={intl.formatMessage({id:Tr.abandon})}
    onClose={onClose}
    onResolve={onResolve}
    missingInvoices={processes}
    open={hasToWait}
    enableRejectButton
    listMode
  />;
});
