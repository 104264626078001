import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {ReactNode} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {CheckWhiteListDataModel} from '../../../../../../services/helpers/NotificationDataParsers';

export class WhiteListValidationEvent extends KSeFEventsConverter<WebsocketNotificationType.WhiteListValidation> {
  constructor(event: WebSocketNotificationFragment) {
    super(WebsocketNotificationType.WhiteListValidation, event);
  }

  public get actionElement(): undefined {
    return;
  }

  public get notification(): ReactNode {
    const successCount = this.model.data?.invoiceIds?.length ?? 0;
    return this.createNotification({id: this.model.hasError ? Tr.whiteListCheckError : Tr.whiteListCheckSuccess, values: {count: successCount}});
  }

  public get model(): { hasError: boolean, data: CheckWhiteListDataModel | null } {
    const data = this.parser.parse();
    const hasError = !!this.event.ErrorType || (!!data?.invoiceIds?.length && !!data?.errorItems?.length);
    return {hasError, data};
  }

  public action(): void {
    return;
  }
}
