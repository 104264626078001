import {NotificationDataService} from '../../services/NotificationDataService';
import {
  WebSocketNotificationFragment,
  WebsocketNotificationType,
  WhiteListCheckMutationVariables,
} from '@symfonia-ksef/graphql';
import {CheckWhiteListDataModel, WhiteListItem} from '../../services/helpers/NotificationDataParsers';
import {CheckWhiteListJobRunner} from '../../services/KSeFJobRunners/CheckWhiteListJobRunner';
import {action, computed, makeObservable, observable} from 'mobx';
import {
  NotificationDataResultManager,
  NotificationDataResultManagerI,
} from '../../services/NotificationDataResultManager';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {AlertConfig} from '../../services/helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export type Issuer = { identifier: string, issuerName: string }

export class CheckWhiteListService extends NotificationDataService<WebsocketNotificationType.WhiteListValidation, WhiteListCheckMutationVariables, CheckWhiteListJobRunner, CheckWhiteListDataModel> {

  @observable
  public modalIsActive: boolean = false;

  @observable
  public hasError: boolean = false;

  @observable
  public downloadType: WebsocketNotificationType | undefined = undefined;

  protected dataResultManager: NotificationDataResultManagerI<CheckWhiteListDataModel>;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super(envObserver, earchiveState, () => new CheckWhiteListJobRunner(envObserver, earchiveState));
    makeObservable(this);
    this.dataResultManager = new NotificationDataResultManager<CheckWhiteListDataModel>(this);
  }

  @computed
  public get currentResult(): CheckWhiteListDataModel | null {
    return this.dataResultManager.currentResult;
  }

  @computed
  public get allIssuers(): Issuer[] {
    return this.getAllIssuers(this.currentResult);
  }

  @computed
  public get notWhiteListedIssuers(): Issuer[] {
    return this.getNotWhiteListedIssuers(this.currentResult);
  }

  @computed
  public get allIssuersIncludedErrors(): Issuer[] {
    return this.getAllIssuers(this.currentResult, true);
  }

  @computed
  public get whiteListed(): WhiteListItem[] {
    return this.currentResult?.whiteListed ?? [];
  }

  @computed
  public get notWhiteListed(): WhiteListItem[] {
    return this.currentResult?.notWhiteListed ?? [];
  }

  @computed
  public get errorItems(): WhiteListItem[] {
    return this.currentResult?.errorItems ?? [];
  }

  @computed
  public get errorInvoicesCount(): number {
    return this.getErrorInvoicesCount(this.currentResult);
  }

  @computed
  public get allInvoicesCount(): number {
    return this.getAllInvoicesCount(this.currentResult);
  }

  @computed
  public get notWhiteListedInvoices(): string[] {
    return this.getNotWhiteListedInvoices(this.currentResult);
  }

  public checkIsPending(invoiceId?: string): boolean {
    if (!invoiceId) {
      return false;
    }
    return this.activeEvents.some(event => event.data?.invoiceIds?.includes(invoiceId));
  }

  public getNotWhiteListedInvoices(dataModel?: CheckWhiteListDataModel | null): string[] {
    if (!dataModel) {
      return [];
    }
    const notWhiteListedInvoices: Record<string, string> = {};
    const {notWhiteListed, whiteListed, errorItems} = dataModel;
    for (const item of [...notWhiteListed, ...whiteListed, ...errorItems]) {
      if (item.whiteListResult.every(result => !result.IsWhiteListed)) {
        notWhiteListedInvoices[item.invoiceId] = item.invoiceId;
      }

    }
    return Object.values(notWhiteListedInvoices);
  }

  public handleSuccess(dataModel?: CheckWhiteListDataModel | null): () => AlertConfig {
    return () => {
      dataModel ??= this.currentResult;
      const notWhiteListedInvoices = this.getNotWhiteListedInvoices(dataModel);
      const invoicesWithAccountsCount = this.getInvoicesWithAccountsCount(dataModel);
      const errorsCount = this.getErrorInvoicesCount(dataModel);
      const invoicesCount = this.getAllInvoicesCount(dataModel);
      if (!invoicesWithAccountsCount) {
        return {id: Tr.whiteListCheckAllAccountsIsEmpty, values: {count: invoicesCount}};
      }
      if (errorsCount) {
        return {
          id: Tr.whiteListCheckPartialError,
          color: ToastVariant.ERROR,
          duration: null,
        };
      }
      if (notWhiteListedInvoices.length) {
        return {
          id: Tr.whiteListCheckSuccessWithNotWhiteListed,
          values: {
            notWhiteListedCount: notWhiteListedInvoices.length,
            count: invoicesCount,
          },
        };
      }
      return {
        id: Tr.whiteListCheckSuccess,
        values: {invoicesCount},
      };
    };
  }

  public handleError(): () => AlertConfig {
    return () => ({
      id: Tr.whiteListChangeError,
    });
  }

  public handleAlerts(dataModel?: CheckWhiteListDataModel | null): {
    success: () => AlertConfig | undefined,
    error: () => AlertConfig | undefined
  } {
    return {
      success: this.handleSuccess(dataModel),
      error: this.handleError(),
    };
  }

  public getErrorInvoicesCount(dataModel: CheckWhiteListDataModel | null): number {
    return dataModel?.errorItems?.length ?? 0;
  }

  public getAllInvoicesCount(dataModel: CheckWhiteListDataModel | null): number {
    return dataModel?.invoiceIds?.length ?? 0;
  }

  @action.bound
  public setDownloadType(downloadType: WebsocketNotificationType | undefined): this {
    this.downloadType = downloadType;
    return this;
  }

  @action.bound
  public setArchivedResult(result: CheckWhiteListDataModel, NotificationId: string): this {
    this.dataResultManager.setArchivedResult(result, NotificationId);
    return this;
  }

  public setModalIsActive(isActive: boolean): this {
    this.modalIsActive = isActive;
    return this;
  }

  protected async onSuccess(data: WebSocketNotificationFragment): Promise<void> {
    super.onSuccess(data);
    if (!this.result) {
      return;
    }
    this.dataResultManager.setFetchingResult({...this.result}, this.notification);
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void {
    console.error({errors, error});
    this.setHasError(true);
  }

  protected override beforeFetch(): void | Promise<void> {
    this.setHasError(false);
  }

  private getInvoicesWithAccountsCount(dataModel: CheckWhiteListDataModel | null): number {
    if (!dataModel) {
      return 0;
    }
    const uniqueInvoicesIds: Record<string, boolean> = {};
    const {notWhiteListed, whiteListed, errorItems} = dataModel;
    for (const item of [...notWhiteListed, ...whiteListed, ...errorItems]) {
      uniqueInvoicesIds[item.invoiceId] = true;
    }
    return Object.keys(uniqueInvoicesIds).length;
  }

  private getAllIssuers(dataModel: CheckWhiteListDataModel | null, withErrors: boolean = false): Issuer[] {
    if (!dataModel) {
      return [];
    }
    const uniqueIssuers: Record<string, string> = {};
    const {notWhiteListed, whiteListed, errorItems} = dataModel;
    for (const item of [...notWhiteListed, ...whiteListed, ...(withErrors ? errorItems : [])]) {
      uniqueIssuers[item.identifier] = item.issuerName;
    }
    return Object.entries(uniqueIssuers).map(([identifier, issuerName]) => ({identifier, issuerName}));
  }

  private getNotWhiteListedIssuers(dataModel: CheckWhiteListDataModel | null): Issuer[] {
    if (!dataModel) {
      return [];
    }
    const notWhiteListedIssuersUnique: Record<string, string> = {};
    const {notWhiteListed} = dataModel;
    for (const item of notWhiteListed) {
      notWhiteListedIssuersUnique[item.identifier] = item.issuerName;
    }
    return Object.entries(notWhiteListedIssuersUnique).map(([identifier, issuerName]) => ({identifier, issuerName}));
  }

  @action
  private setHasError(hasError: boolean): void {
    this.hasError = hasError;
  }
}
