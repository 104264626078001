import {
    GetContractorsDocument,
    GetContractorsQuery,
    GetContractorsQueryVariables
  } from '@symfonia-ksef/graphql';
  import {
    BaseTableDataRepositoryService,
    BaseTableDataRepositoryServiceI,
  } from '../../../root/services/TableServices/DataSourceTableService';
  import {makeObservable} from 'mobx';
  import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';
import {ContractorsFiltersState} from './ContractorsTable/ContractorsFiltersState';
  
  export type ContractorsRepositoryI = BaseTableDataRepositoryServiceI<keyof GetContractorsQuery, GetContractorsQuery, GetContractorsQueryVariables>
  
  export class ContractorsRepository extends BaseTableDataRepositoryService<'GetContractors', GetContractorsQuery, GetContractorsQueryVariables> implements ContractorsRepositoryI {
    constructor(private readonly filters: ContractorsFiltersState) {
      super('GetContractors', GetContractorsDocument, envObserver, earchiveState);
      this.configure({SearchText: ''});
      makeObservable(this);
    }
  
    public override checkIsReady(): boolean {
      return super.checkIsReady() && typeof this.variables.Skip === 'number' && this.variables.Take;
    }

    protected override beforeFetch(): void {
      this.configure({ContractorFiltersFields: this.filters.filtersForQuery});
    }
  }
  