import {useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import {usePageTitle} from '../../../../common';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Breadcrumbs, DropdownSize, DropdownWidth} from '@symfonia/brandbook';
import {Header} from '../../../../root/components/Header';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {ExtendedTable} from '../../../../root/components/ExtendedTable';
import {ContractorsService} from '../../../modules/Contractors/Contractors.service';
import {useModule} from '../../../../root/services/MobXServices/BaseModule';
import _ from 'lodash';
import TableHeader from '../../../components/TableHeader/TableHeader';
import {ContractorsAccountsModal} from '../../../modules/Contractors/ContractorsTable/ContractorsAccountsModal';
import {useContractorsTableColumnsConfig} from '../../../hooks/useContractorsTableConfiguration';
import {useInvoicesTableColumnsConfig} from '../../../hooks/useInvoicesTableConfiguration';
import {ContractorsModal} from '../../../components/InvoicesTable/Components/ContractorsModal';
import {GroupContractorsActionPanel} from '../../../modules/Contractors/ContractorsTable/GroupContractorsActionPanel';

export const ContractorsPage = observer(() => {
	const {setPageTitle} = usePageTitle();
	const intl = useIntl();

  setPageTitle(intl.formatMessage({id: Tr.contractors}));

	const {
    contractorAccountsModalProps,
    closeContractorAccountsModal,
    setContractorAccountsModalProps,
  } = useContractorsTableColumnsConfig();

	const {
    contractorModalProps,
    closeContractorModal,
    confirmContractorModal,
    setContractorModalProps,
  } = useInvoicesTableColumnsConfig();

	const module = useModule(() => new ContractorsService(setContractorAccountsModalProps, setContractorModalProps));

	const dropdownProps = {
		width: DropdownWidth.FULL,
		options: module.scope.supplierTypeVariants,
		size: DropdownSize.SM,
		onChange: ([value]: string[]) => module.scope.setVariant(value),
		className: 'min-w-[220px]',
		placeholder: intl.formatMessage({id: Tr.ContractorTypeSwitchesHeader}),
		value: module.scope.currentVariant,
	};

	const updateContractors = (supplierType: number) => {
		confirmContractorModal(supplierType, () => module.repository.fetch());
	};

	const breadcrumbs = [
		{
			label: intl.formatMessage({id: Tr.settings}),
		},
		{
			label: intl.formatMessage({id: Tr.contractors}),
		},
	];

	return (
		<PageContent>
			<div className="flex flex-col gap-2 lg:ml-[40px]">
				<Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'}/>
				<div className="max-w-[650px]">
					<Header>{intl.formatMessage({id: Tr.contractorsList})}</Header>
				</div>

					<TableHeader
            dropdown={dropdownProps}
            searchService={module.searchService}
            translationKey={Tr.search}
					/>
					<GroupContractorsActionPanel
						selectionState={module.tableService}
						filtersState={module.filters}
						refreshData={() => module.repository.fetch()}
					/>
					<ExtendedTable
            service={module.tableService}
            loaderEnabled
					/>
			</div>
			{contractorAccountsModalProps && 
				<ContractorsAccountsModal
					close={closeContractorAccountsModal}
					modalProps={contractorAccountsModalProps}
				/>
			}
			{contractorModalProps && 
				<ContractorsModal
					confirm={(supplierType) => updateContractors(supplierType)}
					close={closeContractorModal}
					modalProps={contractorModalProps}
				/>
			}
		</PageContent>
	);
});