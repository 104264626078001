import {Tr} from '@symfonia-ksef/locales/keys';
import {FC, useState} from 'react';
import {ContractorTypeSwitches} from '../../../components/InvoicesTable/Components/ContractorTypeSwitches';
import {intl} from '../../../../root/IntlProvider';
import {ButtonPrimary, ButtonPrimarySize, ButtonPrimaryVariant, ButtonPrimaryWidth, ButtonSecondary, ButtonSecondarySize, ButtonSecondaryVariant, ButtonSecondaryWidth, ButtonTertiary, IconColor, IconSize, IconSvg, IconVariant, Separator, ToastVariant} from '@symfonia/brandbook';
import {DialogModal} from '@symfonia/symfonia-ksef-components';

export interface ContractorSwitchTypeModalProps {
  contractorNips: string[];
  modalIsActive: boolean;
}

export const ContractorsSwitchTypeModal: FC<{
  confirm: (supplierType: number) => void;
  close: () => void;
  modalProps: ContractorSwitchTypeModalProps;
}> = ({confirm, close, modalProps}) => {
  const [supplierType, setSupplierType] = useState<number>();

  const onConfirm = () => {
    confirm(supplierType ?? 0);
  };
  
  return (
    <DialogModal className={'min-w-[500px]'} open={modalProps.modalIsActive} onCancel={close} setMaxWidth={false} testId="ContractorSwitchTypeGroupModal"
      title={<div>{intl.formatMessage({id: Tr.changeSelectedContractorsType})} </div>}>
      <span>{intl.formatMessage({id: Tr.selectedContractorsCount})} {modalProps.contractorNips.length}</span>
      <Separator className='my-[15px]'/>
      <ContractorTypeSwitches supplierType={supplierType ?? 0} setSupplierType={setSupplierType} testId="ContractorTypeGroupSwitches"/>
      <div className="flex flex-row justify-end items-center w-full px-0 mt-[40px]">
        <div>
          <ButtonSecondary text={intl.formatMessage({id: Tr.cancelButton})} onClick={close} variant={ButtonSecondaryVariant.GREEN} size={ButtonSecondarySize.MD} width={ButtonSecondaryWidth.INITIAL} className='mr-[10px]' testId="ContractorSwitchTypeGroupModal"/>
          <ButtonPrimary text={intl.formatMessage({id: Tr.save})} onClick={onConfirm} variant={ButtonPrimaryVariant.GREEN} size={ButtonPrimarySize.MD} width={ButtonPrimaryWidth.INITIAL} testId="ContractorSwitchTypeGroupModal"/>
        </div>
      </div>
    </DialogModal>
  );
};
