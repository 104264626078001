import {Action} from '../../../../common/types/MenuActions';
import {InvoicesRow} from '../../../modules/Invoices/InvoicesTable/invoicesTableConfig';
import * as React from 'react';
import {ReactNode, useMemo} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {InvoicePostingStatus, KSeFAuthResult, KSeFStatus, KSeFTokenCredential} from '@symfonia-ksef/graphql';
import {GroupedActionTypeEnum} from '../../../models';
import {SelectionI} from '../../../../root/services/TableServices/ExtendedTableService';
import {useInvoicesTableMethods} from '../../../hooks/useInvoicesTableMethods';
import {useInvoicesViewButtonsAndFiltersMethods} from '../../../hooks/useInvoicesViewButtonsAndFiltersMethods';
import {isSymfoniaTrade} from '../../../../common/helpers/checkCurrentPlatform';
import {isFeatureEnabled} from '../../../../common/helpers/featureSwitch';
import {FeatureSwitchTypeEnum} from '../../../../common/enums/FeatureSwitchTypeEnum';
import {earchiveStateMobx} from '../../../EArchiveModule';
import {IconSvg} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {InvoicesFiltersState} from '../../../modules/Invoices/InvoicesFilters/InvoicesFiltersState';

export type InvoicesGroupActionsParams = {
  selectionState: SelectionI<InvoicesRow>;
  invoicesBound: { isExternal: boolean, isInternal: boolean };
  hasAllInvoicesWithDeprecatedSchemaVersion: boolean,
  setModalOpen: (open: boolean) => void,
  filters: InvoicesFiltersState
}

export function useInvoicesGroupActions(params: InvoicesGroupActionsParams): Action<InvoicesRow>[] {
  const intl = useIntl();
  const {isEmpty} = useInvoicesTableMethods(undefined, params.selectionState);
  const hasInvoiceWrite = earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceWrite);
  const authorizedInKSeF = earchiveState.company.authorizedInKSeF;

  const {isPackageNotActive} = earchiveState.packageStatistics;

  const {
    onGroupedAction,
    upoEnabled,
    exportToSymfoniaFKDisabled,
    deleteInvoicesButtonDisabled,
  } = useInvoicesViewButtonsAndFiltersMethods(params.selectionState);

  const isAnySelectedInvoiceToSend = !!params.selectionState.selected.find((invoice) => [KSeFStatus.NotSent, KSeFStatus.Error].includes(invoice.KSeFStatus));
  const enabledExportToSymfoniaTrade = isSymfoniaTrade();
  const enabledGetUpoPdf = upoEnabled;
  const enabledGetUpoXml = upoEnabled;
  const enabledSendToKSeF = isAnySelectedInvoiceToSend;

  const isSendToKSeFButtonDisabled =
    !enabledSendToKSeF ||
    authorizedInKSeF === KSeFAuthResult.NotAuthorized ||
    isPackageNotActive ||
    !hasInvoiceWrite ||
    isEmpty;

    const sendToKsefButtonTooltip = useMemo<ReactNode>(() => {
      if (params.hasAllInvoicesWithDeprecatedSchemaVersion) {
        return intl.formatMessage({id: Tr.invoiceSentToKsefDisabledCauseSchemaTooltip});
      }
      if (isSendToKSeFButtonDisabled) {
        return <>
            <b>{intl.formatMessage({id: Tr.noInvoiceWriteKsefSendTooltip_Header})}</b>
            <br/>
            <br/>
            {intl.formatMessage({id: Tr.noInvoiceWriteKsefSendTooltip_Content})}
          </>;
      }

    return '';
  }, [isSendToKSeFButtonDisabled, params.hasAllInvoicesWithDeprecatedSchemaVersion]);

  const areAllSelectedInvoicesNotAllowedInFK = !params.selectionState.selected.find((invoice) =>
    ![InvoicePostingStatus.NotAllowed, InvoicePostingStatus.Posting, InvoicePostingStatus.PostingFailed, InvoicePostingStatus.ReadyForPosting].includes(invoice.InvoicePostingStatus));

  const isExportToSymfoniaFKVisible = !isSymfoniaTrade && !areAllSelectedInvoicesNotAllowedInFK;

  return useMemo<Action<InvoicesRow>[]>(() => {
    if (params.invoicesBound.isExternal) {
      return [
        {
          'test-id': "autopostingMultipleAction",
          key: Tr.postInvoice,
          hidden: !isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) || !earchiveStateMobx.postingState.isPostingConfigured,
          label: intl.formatMessage({id: Tr.postInvoice}),
          onClick: () => {
            onGroupedAction(GroupedActionTypeEnum.post);
          },
        },
        {
          'test-id': "whiteListMultipleAction",
          key: Tr.whiteListCheckAction,
          label: intl.formatMessage({id: Tr.whiteListCheckAction}),
          onClick: () => {
            onGroupedAction(GroupedActionTypeEnum.whiteListCheck);
          },
        },
        {
          'test-id': "downloadInvoiceMultipleAction",
          key: Tr.downloadInvoice,
          label: intl.formatMessage({id: Tr.downloadInvoice}),
          children: [
            {
              key: Tr.downloadInvoicePdf,
              label: intl.formatMessage({id: Tr.downloadInvoicePdf}),
              onClick: () => {
                onGroupedAction(GroupedActionTypeEnum.downloadInvoicesZip);
              },
              hidden: false,
              icon: IconSvg.SAVE_ALT,
            },
          ],
        },
        {
          'test-id': "downloadToMultipleAction",
          key: Tr.downloadTo,
          label: intl.formatMessage({id: Tr.downloadTo}),
          children: [
            {
              'test-id': "exportToSymfoniaTradeMultipleAction",
              tooltipTitle: intl.formatMessage({id: Tr.exportToSymfoniaTrade_Tooltip}),
              disabled: !enabledExportToSymfoniaTrade,
              key: Tr.exportToSymfoniaTrade,
              label: intl.formatMessage({id: Tr.exportToSymfoniaTrade}),
              onClick: () => {
                onGroupedAction(GroupedActionTypeEnum.exportToSymfoniaTrade);
              },
              hidden: false,
              icon: IconSvg.SAVE_ALT,
            },
            {
              'test-id': "exportToSymfoniaFinancesAndAccountancyMultipleAction",
              key: Tr.exportToSymfoniaFinancesAndAccountancy,
              label: intl.formatMessage({id: Tr.exportToSymfoniaFinancesAndAccountancy}),
              onClick: () => {
                onGroupedAction(GroupedActionTypeEnum.exportToSymfoniaFinancesAndAccountancy);
              },
              disabled: exportToSymfoniaFKDisabled,
              hidden: !isSymfoniaTrade,
              icon: IconSvg.SAVE_ALT,
            },
          ],
        },
        {
          'test-id': "deleteInvoiceMultipleAction",
          key: Tr.removeButton,
          label: intl.formatMessage({id: Tr.removeButton}),
          disabled: deleteInvoicesButtonDisabled,
          onClick: () => params.setModalOpen(true),
        },
      ];
    }
    return [
      {
        'test-id': "sendToKsefMultipleAction",
        key: Tr.sendToKSeF,
        tooltip: sendToKsefButtonTooltip,
        disabled: isSendToKSeFButtonDisabled || params.hasAllInvoicesWithDeprecatedSchemaVersion,
        label: intl.formatMessage({id: Tr.sendToKSeF}),
        onClick: () => {
          onGroupedAction(GroupedActionTypeEnum.sendToKSeF);
        },
      },
      {
        'test-id': "downloadInvoiceMultipleAction",
        key: Tr.download,
        label: intl.formatMessage({id: Tr.download}),
        children: [
          {
            'test-id': "downloadInvoicePdfMultipleAction",
            key: Tr.downloadInvoicePdf,
            label: intl.formatMessage({id: Tr.downloadInvoicePdf}),
            icon: IconSvg.SAVE_ALT,
            hidden: false,
            onClick: () => {
              onGroupedAction(GroupedActionTypeEnum.downloadInvoicesZip);
            },
          },
          {
            'test-id': "getUpoPdfMultipleAction",
            key: Tr.getUpoPdf,
            label: intl.formatMessage({id: Tr.getUpoPdf}),
            icon: IconSvg.SAVE_ALT,
            disabled: !enabledGetUpoPdf,
            tooltipTitle: !enabledGetUpoPdf ? intl.formatMessage({id: Tr.cantDownloadUpoTooltip}) : null,
            onClick: () => {
              onGroupedAction(GroupedActionTypeEnum.getUpoPdf);
            },
          },
          {
            'test-id': "getUpoXmlMultipleAction",
            disabled: !enabledGetUpoXml,
            key: Tr.getUpoXml,
            label: intl.formatMessage({id: Tr.getUpoXml}),
            icon: IconSvg.SAVE_ALT,
            tooltipTitle: !enabledGetUpoXml ? intl.formatMessage({id: Tr.cantDownloadUpoTooltip}) : null,
            onClick: () => {
              onGroupedAction(GroupedActionTypeEnum.getUpoXml);
            },
          },
        ],
      },
      {
        'test-id': "exportToSymfoniaFinancesAndAccountancyMultipleAction",
        hidden: !isExportToSymfoniaFKVisible,
        key: Tr.exportToSymfoniaFinancesAndAccountancy,
        disabled: exportToSymfoniaFKDisabled,
        label: intl.formatMessage({id: Tr.exportToSymfoniaFinancesAndAccountancy}),
        onClick: () => {
          onGroupedAction(GroupedActionTypeEnum.exportToSymfoniaFinancesAndAccountancy);
        },
      },
      {
        'test-id': "autopostingMultipleAction",
        hidden: !isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) || !earchiveStateMobx.postingState.isPostingConfigured,
        key: Tr.postInvoice,
        label: intl.formatMessage({id: Tr.postInvoice}),
        onClick: () => {
          onGroupedAction(GroupedActionTypeEnum.post);
        },
      },
      {
        'test-id': "deleteInvoiceMultipleAction",
        tooltip: deleteInvoicesButtonDisabled ? intl.formatMessage({id: Tr.cantDeleteInvoiceTooltip}) : undefined,
        key: Tr.removeButton,
        disabled: deleteInvoicesButtonDisabled,
        label: intl.formatMessage({id: Tr.removeButton}),
        onClick: () => params.setModalOpen(true),
      },
    ];
  }, [params.invoicesBound.isExternal, deleteInvoicesButtonDisabled, earchiveStateMobx.postingState.isPostingConfigured, exportToSymfoniaFKDisabled, enabledExportToSymfoniaTrade, isExportToSymfoniaFKVisible, enabledGetUpoXml, enabledGetUpoPdf, sendToKsefButtonTooltip, isSendToKSeFButtonDisabled, params.hasAllInvoicesWithDeprecatedSchemaVersion]);
}
