import {Tr} from '@symfonia-ksef/locales/keys';
import {ConfigMenuSection} from './ConfigMenu';
import {IModuleStatus} from '@symfonia-ksef/state/ModuleSettings/IModuleStatus';
import {IconSvg} from '@symfonia/brandbook';
import {FeatureSwitchTypeEnum} from '../../../common/enums/FeatureSwitchTypeEnum';
import {ModulesEnum} from '../../../common/enums/ModulesEnum';
import {toJS} from 'mobx';
import {earchiveStateMobx} from '../../../earchive';
import {RequiredModulesToFillEnum} from '@symfonia-ksef/graphql';


export const configMenuSections = (
  intl: { formatMessage: (arg0: { id: Tr; }) => any; },
  isRouteActive: (arg0: string) => any,
  modules: IModuleStatus[]): ConfigMenuSection[] => {
    return [
      {
        id: '1',
        sectionName: intl.formatMessage({id: Tr.generalDetails}),
        items: [
          {
            id: '11',
            text: intl.formatMessage({id: Tr.userDetails}),
            active: isRouteActive('user'),
            href: 'user',
            lIcon: IconSvg.PERSON,
            'test-id': 'user',
          },
          {
            id: '12',
            text: intl.formatMessage({id: Tr.tenantDetails}),
            active: isRouteActive('tenant'),
            href: 'tenant',
            lIcon: IconSvg.DOMAIN,
            'test-id': 'tenant',
          },
        ],
      },
      {
        id: '2',
        sectionName: 'Symfonia KSeF',
        items: [
          {
            id: '21',
            text: intl.formatMessage({id: Tr.authentication}),
            active: isRouteActive('authentication'),
            href: 'authentication',
            lIcon: IconSvg.VERIFIED_USER,
            'test-id': 'authentication',
          },
          {
            id: '22',
            text: intl.formatMessage({id: Tr.ksefSettings}),
            active: isRouteActive('ksef_settings'),
            href: 'ksef_settings',
            lIcon: IconSvg.SETTINGS,
            'test-id': 'ksef_settings',
          },
          {
            id: '23',
            text: intl.formatMessage({id: Tr.permissions}),
            active: isRouteActive('permissions'),
            href: 'permissions',
            lIcon: IconSvg.FORMAT_LIST_BULLETED,
            'test-id': 'permissions',
          },
          {
            id: '24',
            text: intl.formatMessage({id: Tr.contractors}),
            active: isRouteActive('contractors'),
            href: 'contractors',
            lIcon: IconSvg.HOW_TO_REG,
            'test-id': 'contractors',
          },
          {
            id: '25',
            text: intl.formatMessage({id: Tr.emailNotifications}),
            active: isRouteActive('notifications'),
            href: 'notifications',
            lIcon: IconSvg.NOTIFICATIONS,
            'test-id': 'notifications',
          },
        ],
      },
      {
        id: '3',
        sectionName: intl.formatMessage({id: Tr.automaticDecrees}),
        featureProps: {
          feature: FeatureSwitchTypeEnum.autoposting,
          module: ModulesEnum.autoPosting,
          moduleStatus: toJS(modules.find(x => x.moduleType === ModulesEnum.autoPosting)),
        },
        items: [
          {
            id: '31',
            text: intl.formatMessage({id: Tr.config}),
            active: isRouteActive('posting'),
            href: 'posting',
            lIcon: IconSvg.ACCOUNT_TREE,
            isRequiredAction: earchiveStateMobx.postingState.requiredModules.includes(RequiredModulesToFillEnum.Category),
            'test-id': 'posting',
          },
          {
            id: '32',
            text: intl.formatMessage({id: Tr.settlements}),
            active: isRouteActive('posting/settlements'),
            href: 'posting/settlements',
            lIcon: IconSvg.EVENT_NOTE,
            isRequiredAction: earchiveStateMobx.postingState.requiredModules.includes(RequiredModulesToFillEnum.SettlementPurchase)
              || earchiveStateMobx.postingState.requiredModules.includes(RequiredModulesToFillEnum.SettlementSales),
            'test-id': 'posting/settlements',
          },
          {
            id: '33',
            text: intl.formatMessage({id: Tr.documentTypes}),
            active: isRouteActive('posting/documentTypes'),
            href: 'posting/documentTypes',
            lIcon: IconSvg.LIST_ALT,
            isRequiredAction: earchiveStateMobx.postingState.requiredModules.includes(RequiredModulesToFillEnum.DocumentTypePurchase)
              || earchiveStateMobx.postingState.requiredModules.includes(RequiredModulesToFillEnum.DocumentTypeSales),
            'test-id': 'posting/documentTypes',
          },
          {
            id: '34',
            text: intl.formatMessage({id: Tr.postingSettings}),
            active: isRouteActive('posting/settings'),
            href: 'posting/settings',
            lIcon: IconSvg.SETTINGS,
            'test-id': 'posting/settings',
          },
        ],
      },
    ];
  };
