import React, {FC, useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useForm, useFormState, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {IdentifierType, KSeFIdentifierType, KSeFTokenCredential} from '@symfonia-ksef/graphql';
import {ItemPermissions} from '../../../models';
import validationSchema from './validationSchema';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ConfirmModal, LoadingButton, PageContent} from '@symfonia/symfonia-ksef-components';
import {observer} from 'mobx-react-lite';
import {earchiveState, grantPermissionsService} from '@symfonia-ksef/state/rootRepository';
import {PermissionAlertType} from '../../../../../state/KSeFSubscriptionServices/GrantPermissionsService';
import {validateNip, validatePesel} from '@symfonia/utils';
import {
  Breadcrumbs,
  ButtonSecondary,
  ButtonTertiary,
  Checkbox,
  Icon,
  IconColor,
  IconSize,
  IconSvg,
  IconVariant,
  Input,
  RadioButtonGroup,
  ToggleGroupOrientation,
  Tooltip,
  TooltipPosition,
} from '@symfonia/brandbook';
import {Header} from '../../../../root/components/Header';

export interface AddEditPermissionProps {
  isEditMode?: boolean;
  onSubmit: (permissionAlertType: PermissionAlertType) => void;
  handleBack: () => void;
}

const AddEditPermission: FC<AddEditPermissionProps> = observer(({isEditMode, onSubmit, handleBack}) => {
  const intl = useIntl();

  const {company: {currentEnvironment, ksefCredentials}} = earchiveState;

  if (!currentEnvironment) {
    throw new Error('selectedCompanyEnvironment not set');
  }

  const {
    jobRunner: {permissions, loading},
  } = grantPermissionsService;

  const isLoading =
    loading || (!!permissions.Identifier && grantPermissionsService.checkIsPending(permissions.Identifier));

  const {control, getValues, setValue, watch, reset, formState} = useForm<ItemPermissions>({
    defaultValues: permissions,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const {isDirty, isValid, isSubmitting, isSubmitted} = useFormState({control});

  const resetPermissionToDefaultValues = useCallback(() => {
    if (isEditMode) {
      reset();
      return grantPermissionsService.setPermissions(permissions);
    }
    return grantPermissionsService.reset(permissions => reset(permissions));
  }, [permissions]);

  useEffect(() => {
    !isEditMode && resetPermissionToDefaultValues();
  }, [isEditMode, resetPermissionToDefaultValues]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openForceEditModal, setOpenForceEditModal] = useState(false);

  const watchIdentifierType = watch('IdentifierType');
  const watchIdentifier = watch('Identifier');
  const isUserSelfEditing = watchIdentifier === currentEnvironment.Company.Identifier;

  const permissionsValid = (): boolean => {
    return (
      watch('CredentialsInvoiceRead') ||
      watch('CredentialsInvoiceWrite') ||
      watch('CredentialsManage') ||
      watch('CredentialsRead') ||
      watch('CredentialsSelfInvoicing')
    );
  };

  const handleDeleteClick = () => {
    setOpenDeleteModal(true);
  };

  const onCancelDeletePermission = () => {
    setOpenDeleteModal(false);
  };

  const onCancelForceEditPermission = () => {
    setOpenForceEditModal(false);
  };

  useEffect(() => {
    if (watchIdentifierType === IdentifierType.Nip && validatePesel(watchIdentifier)) {
      setValue('IdentifierType', IdentifierType.Pesel);
      setValue('Identifier', watchIdentifier, {shouldValidate: true, shouldDirty: true});
    } else if (watchIdentifierType === IdentifierType.Pesel && validateNip(watchIdentifier)) {
      setValue('IdentifierType', IdentifierType.Nip);
      setValue('Identifier', watchIdentifier, {shouldValidate: true, shouldDirty: true});
    }
  }, [watchIdentifier]);

  useEffect(() => {
    if (isUserSelfEditing) {
      setValue(
        'CredentialsManage',
        ksefCredentials.includes(KSeFTokenCredential.CredentialsManage),
      );
    } else if (!isEditMode) {
      setValue('CredentialsManage', false);
    }
  }, [isUserSelfEditing]);

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.permissions}),
      onClick: () => handleBack()
    },
    {
      label: intl.formatMessage({id: Tr.AddPermissions}),
    },
  ];

  const radioButtons = [
    {
      label: intl.formatMessage({id: Tr.Nip}),
      value: IdentifierType.Nip,
      disabled: isEditMode,
    },
    {
      label: intl.formatMessage({id: Tr.PESEL}),
      value: IdentifierType.Pesel,
      disabled: isEditMode,
    },
  ];

  return (
    <PageContent>
      <div className="flex flex-col gap-2 lg:ml-[40px] w-[50%]">
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <ButtonTertiary
          onClick={handleBack}
          text={intl.formatMessage({id: Tr.backToPermissions})}
          lIcon={IconSvg.CHEVRON_LEFT}
          className="mt-[10px]"
          test-id="backToPermissionsButton"
        />
        <Header className={'whitespace-nowrap'}>{intl.formatMessage({id: isEditMode ? Tr.editPermission : Tr.newPermissions})}</Header>
        <ConfirmModal
          title={intl.formatMessage({id: Tr.deletePermissionTitle})}
          content={
            <FormattedMessage
              id={Tr.deletePermissionTableActionContent}
              values={{
                identifier: permissions.Identifier,
              }}
            />
          }
          confirmButtonName={intl.formatMessage({id: Tr.continue})}
          cancelButtonName={intl.formatMessage({id: Tr.cancelButton})}
          open={openDeleteModal}
          onCancel={onCancelDeletePermission}
          test-id="deletePermissionConfirmModal"
          onConfirm={async () => {
            await grantPermissionsService.delete(KSeFIdentifierType.IndividualNip, onSubmit);
            setOpenDeleteModal(false);
          }}
        />
        <ConfirmModal
          title={intl.formatMessage({id: Tr.forceEditPermissionTitle})}
          content={intl.formatMessage({id: Tr.forceEditPermissionContent})}
          confirmButtonName={intl.formatMessage({id: Tr.forceEditButton})}
          cancelButtonName={intl.formatMessage({id: Tr.cancelButton})}
          open={openForceEditModal}
          onCancel={onCancelForceEditPermission}
          test-id="forceEditPermissionConfirmModal"
          onConfirm={async () => {
            await grantPermissionsService.edit(KSeFIdentifierType.IndividualNip, onSubmit);
            setOpenDeleteModal(false);
          }}
        />
        <Controller
          name="IdentifierType"
          control={control}
          render={({field}) => (
            <RadioButtonGroup
              onChange={checked => field.onChange(checked[0] === IdentifierType.Nip ? IdentifierType.Nip : IdentifierType.Pesel)}
              test-id="identifierTypeRadioButtonGroup"
              value={[field.value]}
              checkboxes={radioButtons}
              orientation={ToggleGroupOrientation.VERTICAL}
              className="pt-[10px] pb-[30px]"
          />
          )}
        />
        <Controller
          name="Identifier"
          control={control}
          render={({field}) => (
            <Input
              test-id="identifierInput"
              isError={!isValid && watchIdentifier !== ''}
              notification={!isValid && watchIdentifier !== '' ? (watchIdentifierType === IdentifierType.Nip ? intl.formatMessage({id: Tr.wrongNip}) : intl.formatMessage({id: Tr.wrongPesel})) : undefined}
              label={
                <div className="flex flex-row">
                  <FormattedMessage id={Tr.PermissionsFor}/>
                  {!isEditMode && (
                    <Tooltip
                      text={
                        watchIdentifierType === IdentifierType.Nip
                          ? intl.formatMessage({id: Tr.nipTooltip_Content})
                          : intl.formatMessage({id: Tr.peselTooltip_Content})
                      }
                    >
                      <Icon
                        className={'ml-[10px]'}
                        svg={IconSvg.INFO}
                        color={IconColor.GREEN_500}
                        variant={IconVariant.FILLED}
                        size={IconSize.MD}
                      />
                    </Tooltip>
                  )}
                </div>
              }
              disabled={isEditMode}
              onInput={value => field.onChange(value)}
              required={true}
              value={field.value}
            />)}
        />
        <div className="mt-[30px] flex flex-col">
          <Tooltip
            text={isUserSelfEditing ? intl.formatMessage({id: Tr.userCannotEditOwnersPermissions_Tooltip}) : ''}
            position={TooltipPosition.BOTTOM}
          >
          <Controller
            name="CredentialsManage"
            control={control}
            render={({field}) => (
              <Checkbox
                test-id="credentialsManageCheckbox"
                label={intl.formatMessage({id: Tr.CredentialsManage})}
                value={''}
                className="mb-[10px]"
                disabled={isUserSelfEditing}
                checked={field.value}
                onChange={value => field.onChange(!field.value)}
              />)} 
          />
          </Tooltip>
          <Controller
            name="CredentialsRead"
            control={control}
            render={({field}) => (
              <Checkbox
                test-id="credentialsReadCheckbox"
                label={intl.formatMessage({id: Tr.CredentialsRead})}
                value={''}
                className="mb-[10px]"
                checked={field.value}
                onChange={value => field.onChange(!field.value)}
              />)
          }/>

          <Controller
            name="CredentialsInvoiceWrite"
            control={control}
            render={({field}) => (
              <Checkbox
                test-id="credentialsInvoiceWriteCheckbox"
                label={intl.formatMessage({id: Tr.CredentialsInvoiceWrite})}
                value={''}
                className="mb-[10px]"
                checked={field.value}
                onChange={value => field.onChange(!field.value)}
              />)}
          />

          <Controller
            name="CredentialsInvoiceRead"
            control={control}
            render={({field}) => (
              <Checkbox
                test-id="credentialsInvoiceReadCheckbox"
                label={intl.formatMessage({id: Tr.CredentialsInvoiceRead})}
                value={Tr.CredentialsInvoiceRead}
                className="mb-[10px]"
                checked={field.value}
                onChange={value => field.onChange(!field.value)}
            />)}
          />
        </div>
        <div className="mt-[30px] flex flex-row justify-end items-center buttonFixed">
          <div className="grow"/>
          <ButtonSecondary
            test-id="permissionsCancelButton"
            className="mr-[10px]"
            disabled={!isDirty || isSubmitting || isSubmitted}
            onClick={resetPermissionToDefaultValues}
            text={intl.formatMessage({id: Tr.cancelButton})}
          />
          <Tooltip text={isUserSelfEditing ? intl.formatMessage({id: Tr.userCannotEditOwnersPermissions_Tooltip}) : ''}>
            <div>
              <LoadingButton
                test-id="permissionsSaveButton"
                className="mr-[10px]"
                loading={isLoading}
                text={intl.formatMessage({id: isEditMode ? Tr.save : Tr.AddPermissions})}
                onClick={() => {
                  if (isEditMode) {
                    grantPermissionsService.setPermissions({...getValues(), IsNew: false});
                    grantPermissionsService.edit(KSeFIdentifierType.IndividualNip, onSubmit);
                  } else {
                    grantPermissionsService.setPermissions({...getValues(), IsNew: true});
                    grantPermissionsService.add(KSeFIdentifierType.IndividualNip, {
                      onSuccess: onSubmit,
                      onError: errors => {
                        if (!errors?.length) {
                          return;
                        }
                        const validationErrors = errors
                          .filter(err => err.extensions.code === 'validation')
                          .map(err => err.extensions);
                        const identifierErrors = validationErrors.find(err => !!err.data && 'Identifier' in err.data)
                          ?.data?.Identifier;
                        if (identifierErrors?.length) {
                          setOpenForceEditModal(true);
                        }
                      },
                    });
                  }
                }}
                disabled={!isDirty || !isValid || !permissionsValid() || isUserSelfEditing}
              />
            </div>
          </Tooltip>
          {isEditMode && (
            <Tooltip
              text={intl.formatMessage({
                id: isUserSelfEditing
                  ? Tr.userCannotDeleteOwnersPermissions_Tooltip
                  : Tr.removePermissionButtonTooltip_Content,
              })}
              onOpen={() => {
                setTimeout(() => {
                  return;
                }, 2000);
              }}
            >
              <div>
                <LoadingButton
                  test-id="permissionsDeleteButton"
                  loading={isLoading}
                  onClick={handleDeleteClick}
                  disabled={isUserSelfEditing}
                  text={intl.formatMessage({id: Tr.removeButton})}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </PageContent>
  );
});

export default AddEditPermission;
